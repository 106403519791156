var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": "",
      "header-bg-variant": "success",
      "header-text-variant": "white",
      "header-tag": "header"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('h3', {
          staticClass: "mb-0 text-white"
        }, [_c('b', [_vm._v(_vm._s(_vm.$t('Supplier Information')))])])];
      },
      proxy: true
    }])
  }, [_c('hr', {
    staticStyle: {
      "height": "3px",
      "background-color": "black",
      "border": "none"
    }
  }), _c('b-card-body', [_c('b-row', {}, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Purchase Order (PO) No.",
      "label-for": "h-po",
      "label-cols-md": "4"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "h-po"
    },
    model: {
      value: _vm.form.no,
      callback: function ($$v) {
        _vm.$set(_vm.form, "no", $$v);
      },
      expression: "form.no"
    }
  }), _c('small', [_c('i', [_vm._v("Jika ingin otomatis, maka kosongkan form")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "h-supplier",
      "label-cols-md": "4"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" Supplier "), _c('span', {
          staticClass: "text-danger"
        }, [_c('small', [_vm._v("(*)")])])];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    attrs: {
      "id": "h-supplier",
      "options": _vm.suppliers,
      "placeholder": "-- Select Supplier --",
      "label": "text"
    },
    on: {
      "change": _vm.fillForm
    },
    model: {
      value: _vm.supplier,
      callback: function ($$v) {
        _vm.supplier = $$v;
      },
      expression: "supplier"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Address",
      "label-for": "h-address",
      "label-cols-md": "4"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.supplier.alamat,
      callback: function ($$v) {
        _vm.$set(_vm.supplier, "alamat", $$v);
      },
      expression: "supplier.alamat"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Phone/HP",
      "label-for": "h-phone",
      "label-cols-md": "4"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.supplier.nohp,
      callback: function ($$v) {
        _vm.$set(_vm.supplier, "nohp", $$v);
      },
      expression: "supplier.nohp"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "h-email",
      "label-cols-md": "4"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.supplier.email,
      callback: function ($$v) {
        _vm.$set(_vm.supplier, "email", $$v);
      },
      expression: "supplier.email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "PIC",
      "label-for": "h-nama",
      "label-cols-md": "4"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.supplier.nama,
      callback: function ($$v) {
        _vm.$set(_vm.supplier, "nama", $$v);
      },
      expression: "supplier.nama"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Category",
      "label-for": "h-category",
      "label-cols-md": "4"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" Category "), _c('span', {
          staticClass: "text-danger"
        }, [_c('small', [_vm._v("(*)")])])];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    attrs: {
      "options": [{
        value: 'Frame',
        text: 'Frame'
      }, {
        value: 'Lensa',
        text: 'Lensa'
      }, {
        value: 'Packaging',
        text: 'Packaging'
      }, {
        value: 'Accs',
        text: 'Accs'
      }],
      "label": "text"
    },
    model: {
      value: _vm.form.kategori,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kategori", $$v);
      },
      expression: "form.kategori"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-card', {
    staticClass: "mb-2",
    attrs: {
      "no-body": "",
      "header-bg-variant": "success",
      "header-text-variant": "white",
      "header-tag": "header"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('h3', {
          staticClass: "mb-0 text-white"
        }, [_c('b', [_vm._v(_vm._s(_vm.$t('Shipment Information')))])])];
      },
      proxy: true
    }])
  }, [_c('hr', {
    staticStyle: {
      "height": "3px",
      "background-color": "black",
      "border": "none"
    }
  }), _c('b-card-body', [_c('b-row', {}, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "h-po-date",
      "label-cols-md": "4"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v("Purchase Order (PO) Date ")];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    staticClass: "w-full",
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tanggal,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tanggal", $$v);
      },
      expression: "form.tanggal"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "h-required-date",
      "label-cols-md": "4"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v("Required Date ")];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    staticClass: "w-full",
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tanggal_kirim,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tanggal_kirim", $$v);
      },
      expression: "form.tanggal_kirim"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "h-eta-date",
      "label-cols-md": "4"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v("Estimated Time Arrival (ETA) ")];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    staticClass: "w-full",
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tanggal_terima,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tanggal_terima", $$v);
      },
      expression: "form.tanggal_terima"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Ship By",
      "label-for": "h-ship-by",
      "label-cols-md": "4"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.form.ship_by,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ship_by", $$v);
      },
      expression: "form.ship_by"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Shipping Cost",
      "label-for": "h-shipcost",
      "label-cols-md": "4"
    }
  }, [_c('b-input-group', {
    attrs: {
      "prepend": "Rp "
    }
  }, [_c('b-form-input', {
    on: {
      "keyup": function ($event) {
        return _vm.doFormatRupiah1();
      }
    },
    model: {
      value: _vm.form.shipping_cost,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shipping_cost", $$v);
      },
      expression: "form.shipping_cost"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "VAT (percent)",
      "label-for": "h-vat",
      "label-cols-md": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-form-input', {
    staticStyle: {
      "width": "10ch"
    },
    attrs: {
      "id": "h-vat",
      "type": "number",
      "placeholder": "VAT"
    },
    model: {
      value: _vm.form.vat_11,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vat_11", $$v);
      },
      expression: "form.vat_11"
    }
  }), _c('span', {
    staticStyle: {
      "margin-left": "0.5rem",
      "margin-right": "1.5rem"
    }
  }, [_vm._v(" %")]), _c('span', [_vm._v("=")]), _c('span', {
    staticClass: "ml-auto"
  }, [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.vatAmount)))])], 1)])], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Category",
      "label-for": "h-category",
      "label-cols-md": "4"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" Notes ")];
      },
      proxy: true
    }])
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.form.keterangan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "keterangan", $$v);
      },
      expression: "form.keterangan"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": "",
      "header-bg-variant": "success",
      "header-text-variant": "white",
      "header-tag": "header"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('h3', {
          staticClass: "mb-0 text-white"
        }, [_c('b', [_vm._v(_vm._s(_vm.$t('Item(s) Details')))])])];
      },
      proxy: true
    }])
  }, [_c('hr', {
    staticStyle: {
      "height": "3px",
      "background-color": "black",
      "border": "none"
    }
  }), _c('b-card-body', [_c('import-barang', {
    on: {
      "getId": _vm.getId
    }
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "2"
    }
  }, [_c('b-button', {
    staticClass: "mb-1 mr-1",
    attrs: {
      "block": "",
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.chooseBarang($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" " + _vm._s('Add Product'))], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "1"
    }
  }, [_c('b-button', {
    staticClass: "mb-1 mr-1",
    attrs: {
      "block": "",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.chooseImport($event);
      }
    }
  }, [_vm._v(_vm._s('Import'))])], 1), _c('b-col', {
    attrs: {
      "lg": "7"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', [_c('b-button', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("by Product")])], 1), _c('b-form-input', {
    attrs: {
      "id": "filter-input",
      "type": "search",
      "placeholder": "Press \"enter\" to search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Clear')))])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-warning",
      "block": ""
    }
  }, [_c('span', {
    staticClass: "text-warning"
  }, [_vm._v("Total : " + _vm._s(_vm.selectedBarangs.length))])])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.selectedBarangs.length > 0 ? _c('b-table', {
    attrs: {
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.selectedBarangs,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    scopedSlots: _vm._u([{
      key: "table-colgroup",
      fn: function (_ref) {
        var fields = _ref.fields;
        return _vm._l(fields, function (field) {
          return _c('col', {
            key: field.key,
            style: {
              width: field.key == 'no' ? '20px' : ''
            }
          });
        });
      }
    }, {
      key: "cell(no)",
      fn: function (_ref2) {
        var index = _ref2.index;
        return [_vm._v(" " + _vm._s(1 + index) + " ")];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.kode ? item.kode : '-')), _c('br'), _c('small', [_vm._v(_vm._s(item.varian_item[0].no_batch))])];
      }
    }, {
      key: "cell(kategori)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.kategori ? item.kategori.kategori : '-') + " ")];
      }
    }, {
      key: "cell(no_container)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Ex: MRT123213"
          },
          model: {
            value: item.no_container,
            callback: function ($$v) {
              _vm.$set(item, "no_container", $$v);
            },
            expression: "item.no_container"
          }
        })];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [item ? _c('span', [_vm._v(" " + _vm._s(item.varian_item[0].varian)), _c('br'), _c('small', [_vm._v(_vm._s(item.kategori.kategori))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.nama))])]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Barang tidak ada.")])];
      }
    }, {
      key: "cell(no_seal)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Ex: GR123"
          },
          model: {
            value: item.no_seal,
            callback: function ($$v) {
              _vm.$set(item, "no_seal", $$v);
            },
            expression: "item.no_seal"
          }
        })];
      }
    }, {
      key: "cell(harga_beli)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatRupiah(item.harga_beli)))]), item.diskon && item.diskon > 0 ? _c('strong', {
          staticClass: "text-danger"
        }, [_vm._v(" diskon " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(diskon)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")];
      }
    }, {
      key: "cell(qty)",
      fn: function (_ref10) {
        var item = _ref10.item,
          index = _ref10.index;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-form-input', {
          staticStyle: {
            "width": "10ch"
          },
          attrs: {
            "id": "h-qtyitem"
          },
          on: {
            "keyup": function ($event) {
              return _vm.doFormatRupiah(index);
            }
          },
          model: {
            value: item.qty,
            callback: function ($$v) {
              _vm.$set(item, "qty", $$v);
            },
            expression: "item.qty"
          }
        }), _c('span', {
          staticStyle: {
            "margin-left": "0.5rem",
            "margin-right": "0.5rem"
          }
        }, [_vm._v("/")]), _c('span', [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : "") + " ")])], 1)];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref11) {
        var item = _ref11.item,
          index = _ref11.index;
        return [_c('feather-icon', {
          staticClass: "text-danger cursor-pointer",
          attrs: {
            "icon": "MinusCircleIcon",
            "size": "24"
          },
          on: {
            "click": function ($event) {
              return _vm.removeBarang(item, index);
            }
          }
        })];
      }
    }], null, false, 2502755510)
  }) : _c('div', {
    staticClass: "alert alert-danger p-2"
  }, [_vm._v(_vm._s(_vm.$t("Please select item")))]), _c('hr')], 1), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: " px-2"
  }, [_c('b-row', {
    staticClass: "py-1 border-bottom align-items-center",
    staticStyle: {
      "border-width": "3px"
    }
  }, [_c('b-col', {
    staticClass: "text-right"
  }, [_c('h5', {
    staticClass: "font-weight-bold"
  }, [_c('b', [_vm._v("TOTAL")])])]), _c('b-col', [_c('h5', {
    staticClass: "font-weight-bold"
  }, [_vm._v(":")])]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('h5', [_c('b', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.totalPembelian)))])])])], 1), _c('b-row', {
    staticClass: "py-1 border-bottom align-items-center"
  }, [_c('b-col', {
    staticClass: "text-right"
  }, [_c('h5', [_vm._v("Shipping Cost")])]), _c('b-col', [_c('h5', {
    staticClass: "font-weight-bold"
  }, [_vm._v(":")])]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('h5', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.form.shipping_cost)))])])], 1), _c('b-row', {
    staticClass: "py-1 border-bottom align-items-center"
  }, [_c('b-col', {
    staticClass: "text-right"
  }, [_c('h5', [_vm._v("VAT")])]), _c('b-col', [_c('h5', {
    staticClass: "font-weight-bold"
  }, [_vm._v(":")])]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('h5', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.vatAmount)))])])], 1), _c('b-row', {
    staticClass: "py-1 border align-items-center border-warning",
    staticStyle: {
      "border-width": "3px"
    }
  }, [_c('b-col', {
    staticClass: "text-right"
  }, [_c('h5', {
    staticClass: "font-weight-bold text-uppercase"
  }, [_c('b', [_vm._v("GRAND TOTAL")])])]), _c('b-col', [_c('h5', {
    staticClass: "font-weight-bold"
  }, [_vm._v(":")])]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('h5', {
    staticClass: "font-weight-bold"
  }, [_c('b', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.grandTotal)))])])])], 1)], 1)]), _c('b-col', {
    staticClass: "mt-1"
  }, [_c('barang-modal', {
    on: {
      "submit": _vm.onChooseBarang
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "disabled": !_vm.isValidForm,
      "variant": !_vm.isValidForm ? 'secondary' : 'warning'
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Save')))])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }