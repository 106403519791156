export default [
  {
    path: "/outbound/disposal",
    name: "route-disposal",
    component: () => import("@/views/pages/heykama/outbound/disposal/List.vue"),
    meta: {
      authRequired: true,
      module: "Disposal",
      pageTitle: "Disposal",
      breadcrumb: [
        {
          text: "Outbound",
          to: "/outbound",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/outbound/disposal/add",
    name: "route-disposal-add",
    component: () =>
      import("@/views/pages/heykama/outbound/disposal/Store.vue"),
    meta: {
      authRequired: true,
      module: "Disposal",
      pageTitle: "Disposal",
      breadcrumb: [
        {
          text: "Outbound",
          to: "/outbound",
        },
        {
          text: "List",
          to: "/disposal",
        },
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },
  {
    path: "/outbound/ajuanmarketing",
    name: "route-ajuanmarketing",
    component: () => import("@/views/pages/heykama/outbound/surat/List.vue"),
    meta: {
      authRequired: true,
      module: "Ajuan Marketing",
      pageTitle: "Ajuan Marketing",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/outbound/ajuanmarketing/add",
    name: "route-ajuanmarketing-add",
    component: () => import("@/views/pages/heykama/outbound/surat/Store.vue"),
    meta: {
      authRequired: true,
      module: "Ajuan Marketing",
      pageTitle: "Ajuan Marketing",
      breadcrumb: [
        {
          text: "List",
          to: "/outbound/ajuanmarketing",
        },
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/disposal/detail/:id',
  //   name: 'route-disposaldetail',
  //   component: () => import('@/views/pages/disposal/DetailList.vue'),
  //   meta: {
  //     parent: '/disposal',
  //     authRequired: true,
  //     module: 'Disposal hilang',
  //     pageTitle: 'Disposal hilang',
  //     breadcrumb: [
  //       {
  //         text: 'Disposal hilang',
  //         to: '/disposal',
  //         // active: true,
  //       },
  //       {
  //         text: 'Detail Barang',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
];
