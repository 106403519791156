var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "disabled": _vm.selectedginee.length == 0,
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.cekstokginee();
      }
    }
  }, [_vm._v(" Cek Stok Ginee (" + _vm._s(_vm.selectedginee.length ? _vm.selectedginee.length : 0) + ") ")]), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "disabled": _vm.selectedginee.length == 0,
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.openModalmin();
      }
    }
  }, [_vm._v(" Change Minimum Stock (" + _vm._s(_vm.selectedginee.length ? _vm.selectedginee.length : 0) + ") ")]), _c('b-modal', {
    ref: "minmodal",
    attrs: {
      "id": "min-modal",
      "title": "Change Minimum Stock",
      "hide-footer": true,
      "size": "md",
      "centered": ""
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.minimum_stok,
      callback: function ($$v) {
        _vm.minimum_stok = $$v;
      },
      expression: "minimum_stok"
    }
  }), _c('br'), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.ubahminstok();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Save")))])], 1), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "disabled": _vm.selectedginee.length == 0,
      "variant": "success"
    },
    on: {
      "click": function ($event) {
        return _vm.updatestokginee();
      }
    }
  }, [_vm._v(" Update Stok WMS ke Ginee (" + _vm._s(_vm.selectedginee.length ? _vm.selectedginee.length : 0) + ") ")])], 1)], 1), _c('hr'), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Category ")]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('b-form-group', [_c('b-form-select', {
    attrs: {
      "options": _vm.itemsKategori
    },
    on: {
      "change": function ($event) {
        return _vm.getpenyimpananBarangs();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("All")) + " --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.kategori_id,
      callback: function ($$v) {
        _vm.kategori_id = $$v;
      },
      expression: "kategori_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Sort ")]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-group', [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 'asc',
        text: 'Ascending'
      }, {
        value: 'desc',
        text: 'Descending'
      }]
    },
    on: {
      "change": function ($event) {
        return _vm.getpenyimpananBarangs();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("None")) + " --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBarang,
      callback: function ($$v) {
        _vm.sortBarang = $$v;
      },
      expression: "sortBarang"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Lainnya ")]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-form-group', [_c('b-input-group', [_c('b-input-group-prepend', [_c('b-form-select', {
    staticClass: "custom-select",
    attrs: {
      "options": [{
        value: 'kelas_id',
        text: 'Kelas'
      }, {
        value: 'main_sku',
        text: 'SKU Ginee'
      }, {
        value: 'kode',
        text: 'Barcode'
      }]
    },
    model: {
      value: _vm.searchOptions,
      callback: function ($$v) {
        _vm.searchOptions = $$v;
      },
      expression: "searchOptions"
    }
  })], 1), _vm.searchOptions !== 'kelas_id' ? _c('b-form-input', {
    attrs: {
      "placeholder": "Masukkan teks"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getpenyimpananBarangs();
      }
    },
    model: {
      value: _vm.customefilter,
      callback: function ($$v) {
        _vm.customefilter = $$v;
      },
      expression: "customefilter"
    }
  }) : _vm._e(), _vm.searchOptions == 'kelas_id' ? _c('b-form-select', {
    attrs: {
      "options": _vm.itemsKelas
    },
    on: {
      "change": function ($event) {
        return _vm.getpenyimpananBarangs();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("All")) + " --")])];
      },
      proxy: true
    }], null, false, 1495490819),
    model: {
      value: _vm.kelas_id,
      callback: function ($$v) {
        _vm.kelas_id = $$v;
      },
      expression: "kelas_id"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Pre Order ")]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('b-form-group', [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 1,
        text: 'Ya'
      }, {
        value: 0,
        text: 'Tidak'
      }]
    },
    on: {
      "change": function ($event) {
        return _vm.getpenyimpananBarangs();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("All")) + " --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.is_pre_order,
      callback: function ($$v) {
        _vm.is_pre_order = $$v;
      },
      expression: "is_pre_order"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-size": "sm",
      "label": _vm.$t('Per page'),
      "label-for": "perPage"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "10",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getpenyimpananBarangs();
      }
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
        _vm.getpenyimpananBarangs();
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingtable
    }
  }, [_vm.penyimpananBarangs.length > 0 ? _c('b-table', {
    attrs: {
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "items": _vm.penyimpananBarangs,
      "fields": _vm.fieldsasli
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedginee,
            callback: function ($$v) {
              _vm.selectedginee = $$v;
            },
            expression: "selectedginee"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item ? item.kode : "-")), _c('br'), _c('small', [_vm._v("SKU WMS : " + _vm._s(item.no_batch))]), _c('br'), _c('small', [_vm._v("SKU Ginee : " + _vm._s(item.main_sku))])];
      }
    }, {
      key: "cell(fotopro)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.photo && item.photo.length > 0 ? [_c('img', {
          staticStyle: {
            "width": "50px"
          },
          attrs: {
            "src": item.photo[0].path,
            "alt": "Image description"
          },
          on: {
            "click": function ($event) {
              return _vm.openModal(item.photo[0].path, item.barcode);
            }
          }
        })] : _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Photo product item not yet created")]), _c('br'), item.barcode ? _c('img', {
          staticStyle: {
            "width": "75px"
          },
          attrs: {
            "src": item.barcode,
            "alt": "Foto"
          },
          on: {
            "click": function ($event) {
              return _vm.openModal(item.photo.length > 0 ? item.photo[0].path : null, item.barcode);
            }
          }
        }) : _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Barcode not yet created")]), _c('b-modal', {
          ref: "imageModal",
          attrs: {
            "id": "image-modal",
            "title": "Preview Photo Product Item and Barcode",
            "hide-footer": true,
            "size": "lg",
            "centered": ""
          }
        }, [_c('div', {
          ref: "imageViewer"
        }, [_vm.currentImage ? _c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": _vm.currentImage,
            "alt": "Full-size"
          }
        }) : _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Photo product item not yet created")]), _c('br'), _vm.currentBarcode ? _c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": _vm.currentBarcode,
            "alt": "Full-size"
          }
        }) : _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Barcode not yet created")])])])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item ? item.nama : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.kategori.kategori))]), _vm._v(" / "), _c('small', [_vm._v(" " + _vm._s(item ? item.varian : "-") + " ")]), _c('br'), _vm._v(" " + _vm._s(item.kelas ? item.kelas.kelas : "-") + " ")];
      }
    }, {
      key: "cell(stok_ginee)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.stok_ginee ? _vm.formatRupiah(item.stok_ginee) : 0) + " ")];
      }
    }, {
      key: "cell(stocks)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('b-card', {
          staticClass: "mb-0",
          attrs: {
            "border-variant": "secondary"
          }
        }, [_c('div', {
          staticClass: "text-center"
        }, [_vm._l(item.stocks, function (data) {
          return [_c('b-row', [_c('b-col', [_c('div', {
            staticClass: "text-right"
          }, [_vm._v(_vm._s(data.blok.blok))])]), _c('b-col', {
            attrs: {
              "md": "1"
            }
          }, [_vm._v(" : ")]), _c('b-col', [data.jumlah > data.minimum_stok ? [_c('div', {
            staticClass: "text-left"
          }, [_c('b', {
            staticClass: "text-success"
          }, [_vm._v(_vm._s(_vm.formatRupiah(data.jumlah)))])])] : _vm._e(), data.jumlah <= data.minimum_stok ? [_c('div', {
            staticClass: "text-left"
          }, [_c('b', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(_vm.formatRupiah(data.jumlah)))])])] : _vm._e()], 2)], 1)];
        }), _c('b-row', [_c('b-col', [_c('div', {
          staticClass: "text-right"
        }, [_vm._v("Total")])]), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_vm._v(" : ")]), _c('b-col', [item.total_stok > _vm.totalJumlah(item.stocks) ? [_c('div', {
          staticClass: "text-left"
        }, [_c('b', {
          staticClass: "text-success"
        }, [_vm._v(_vm._s(_vm.formatRupiah(item.total_stok)))])])] : _vm._e(), item.total_stok <= _vm.totalJumlah(item.stocks) ? [_c('div', {
          staticClass: "text-left"
        }, [_c('b', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.formatRupiah(item.total_stok)))])])] : _vm._e()], 2)], 1)], 2), _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" (Min. Stocks : " + _vm._s(_vm.formatRupiah(item.stocks[0] ? item.stocks[0].minimum_stok : 0)) + ")"), _c('br'), item.stocks.length > 1 ? _c('b-button', {
          attrs: {
            "size": "sm",
            "block": "",
            "variant": "warning"
          },
          on: {
            "click": function ($event) {
              return _vm.modaltransfer(item);
            }
          }
        }, [_vm._v("Stock Transfer")]) : _vm._e()], 1)])];
      }
    }, {
      key: "cell(blok)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.blok ? item.blok.blok : "-") + " ")];
      }
    }, {
      key: "cell(palet)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.palet ? item.palet.palet : "-") + " ")];
      }
    }, {
      key: "cell(rak)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.rak ? item.rak.rak : "-") + " ")];
      }
    }, {
      key: "cell(laci)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.laci ? item.laci.laci : "-") + " ")];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [item.jumlah > item.minimum_stok ? [_c('b', {
          staticClass: "text-success"
        }, [_vm._v(_vm._s(item.jumlah ? item.jumlah : 0))]), _vm._v(" / " + _vm._s(_vm.formatRupiah(item.jumlah * item.harga_dasar))), _c('br'), _c('small', [_vm._v(" (Konversi Satuan : " + _vm._s(item.konversi_ket == null ? 0 : item.konversi_ket) + ") "), _c('br'), _vm._v("(Min. Stok = " + _vm._s(item.minimum_stok) + ") ")])] : _vm._e(), item.jumlah <= item.minimum_stok ? [_c('b', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(item.jumlah ? item.jumlah : 0))]), _vm._v(" / " + _vm._s(_vm.formatRupiah(item.jumlah * item.harga_dasar))), _c('br'), _c('small', [_vm._v(" (Konversi Satuan : " + _vm._s(item.konversi_ket == null ? 0 : item.konversi_ket) + ") "), _c('br'), _vm._v("(Min. Stok = " + _vm._s(item.minimum_stok) + ") ")])] : _vm._e()];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.konversi_ket) + " ")];
      }
    }, {
      key: "cell(selesai)",
      fn: function (data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" " + _vm._s(data.item.status_ket ? data.item.status_ket.toUpperCase() : "-") + " ")])];
      }
    }, {
      key: "cell(po)",
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(item.po ? item.po.no : "po kosong") + " ")];
      }
    }, {
      key: "cell(tanggal_pengiriman)",
      fn: function (_ref14) {
        var item = _ref14.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal)) + " ")];
      }
    }, {
      key: "cell(tanggal_invoice)",
      fn: function (_ref15) {
        var item = _ref15.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal_invoice)) + " ")];
      }
    }, {
      key: "cell(publish)",
      fn: function (_ref16) {
        var item = _ref16.item;
        return [_c('section', {
          staticClass: "flex item-center"
        }, [_c('b-form-checkbox', {
          attrs: {
            "value": true,
            "unchecked-value": false,
            "switch": ""
          },
          on: {
            "change": function ($event) {
              return _vm.togglePublish(item);
            }
          },
          model: {
            value: item.is_published,
            callback: function ($$v) {
              _vm.$set(item, "is_published", $$v);
            },
            expression: "item.is_published"
          }
        })], 1)];
      }
    }, {
      key: "cell(actions)",
      fn: function (_ref17) {
        var item = _ref17.item;
        return [_c('section', {
          staticClass: "flex item-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Detail'",
            "size": "sm",
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push(("/owner/pengiriman/detail/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), _vm.allowUpdate() && item.status == 1 || _vm.allowUpdate() && !_vm.isAdminGudang && !_vm.isSpvcrm ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Batalkan Pengiriman'",
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.batal(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XCircleIcon"
          }
        })], 1) : _vm._e(), _vm.allowUpdate() && item.status == 0 || _vm.allowUpdate() && !_vm.isAdminGudang && !_vm.isSpvcrm ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Ubah'",
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push(("/owner/pengiriman/edit/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete() && item.status == 0 || _vm.allowUpdate() && !_vm.isAdminGudang && !_vm.isSpvcrm ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Hapus'",
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()], 1)];
      }
    }, !_vm.isSpvcrm && !_vm.isSPV ? {
      key: "cell(actionsCabang)",
      fn: function (_ref18) {
        var item = _ref18.item;
        return [_c('section', {
          staticClass: "flex item-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Ubah Barang'",
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.ubahbrg(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "Edit2Icon"
          }
        })], 1), _c('b-button', {
          attrs: {
            "title": "'Ubah Lokasi Penyimpanan'",
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.ubahpeny(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "Edit3Icon"
          }
        })], 1)], 1)];
      }
    } : null], null, true)
  }) : _c('div', {
    staticClass: "alert alert-danger p-2"
  }, [_c('strong', [_vm._v("Data")]), _vm._v(" yang dicari tidak tersedia ")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingtable
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "transfer-modal",
      "title": "Stock Transfer",
      "hide-footer": true,
      "size": "lg",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Total Stock : ")]), _c('b-col', [_c('span', {
    staticStyle: {
      "display": "block",
      "text-align": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.datatf ? _vm.datatf.total_stok : 0) + " " + _vm._s(_vm.datatf ? _vm.datatf.satuan ? _vm.datatf.satuan.satuan : "-" : "-") + " ")])]), _c('b-col', [_c('span', {
    staticStyle: {
      "display": "block",
      "text-align": "left"
    }
  }, [_vm._v(" (Min. Stocks : " + _vm._s(_vm.formatRupiah(_vm.totalJumlah(_vm.datatf ? _vm.datatf.stocks : []))) + ") ")])])], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_c('h6', [_vm._v("Gudang Asal")]), _c('v-select', {
    attrs: {
      "options": _vm.datatfgudang,
      "label": "text"
    },
    model: {
      value: _vm.gudangasal,
      callback: function ($$v) {
        _vm.gudangasal = $$v;
      },
      expression: "gudangasal"
    }
  }), _c('br'), _vm._v(" " + _vm._s(_vm.$t('Stock : ')) + _vm._s(_vm.gudangasal ? _vm.gudangasal.jumlah : 0) + " ")], 1), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "4"
    }
  }, [_c('h6', [_vm._v("Stok yang akan ditransfer")]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.stocktf,
      callback: function ($$v) {
        _vm.stocktf = $$v;
      },
      expression: "stocktf"
    }
  }), _vm.stocktf > (_vm.gudangasal ? _vm.gudangasal.jumlah : 0) ? _c('small', {
    staticClass: "text-danger"
  }, [_vm._v("Stok yang akan ditransfer melebihi stok gudang asal")]) : _vm._e(), _c('br'), _c('b-button', {
    attrs: {
      "block": "",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.ubahstocktf();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Save")))])], 1), _c('b-col', {
    staticClass: "text-left",
    attrs: {
      "cols": "4"
    }
  }, [_c('h6', [_vm._v("Gudang Tujuan")]), _c('v-select', {
    attrs: {
      "options": _vm.datatfgudang,
      "label": "text"
    },
    model: {
      value: _vm.gudangtujuan,
      callback: function ($$v) {
        _vm.gudangtujuan = $$v;
      },
      expression: "gudangtujuan"
    }
  }), _vm._v(" "), _c('br'), _vm._v(" " + _vm._s(_vm.$t('Stock')) + " : " + _vm._s(_vm.gudangtujuan ? _vm.gudangtujuan.jumlah : 0) + " ")], 1)], 1)], 1), _vm.allowCreate() || _vm.allowUpdate() ? _c('b-modal', {
    attrs: {
      "id": "modal-",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": "Form ",
      "size": "xl"
    },
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PackageIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "ml-50 mb-2"
  }, [_vm._v("Penyimpanan Barang")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "8",
      "lg": "8",
      "xl": "8"
    }
  }, [_vm._v("\"Choose Items\")}})\"> "), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6",
      "md": "6",
      "lg": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6",
      "lg": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Sort'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }], null, false, 478637150),
    model: {
      value: _vm.sortBy,
      callback: function ($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function ($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "Cari Barang"
    }
  }, [_vm._v("Cari Barang")]), _c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getDataBarangs();
      }
    },
    model: {
      value: _vm.caribarang,
      callback: function ($$v) {
        _vm.caribarang = $$v;
      },
      expression: "caribarang"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.getDataBarangs();
      }
    }
  }, [_vm._v(" Cari ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_vm.loaded ? _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPageBarangs,
      "items": _vm.dataBarangs,
      "fields": _vm.fieldsBarangs,
      "sort-by": _vm.sortByBarangs,
      "sort-desc": _vm.sortDescBarangs,
      "sort-direction": _vm.sortDirectionBarangs,
      "filter-included-fields": _vm.filterOnBarangs
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortByBarangs = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortByBarangs = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDescBarangs = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDescBarangs = $event;
      },
      "filtered": _vm.onFilteredBarangs
    },
    scopedSlots: _vm._u([{
      key: "cell(penyimpanan)",
      fn: function (data) {
        return [_c('b-form-checkbox', {
          attrs: {
            "value": data.item
          },
          model: {
            value: _vm.pilihbarang,
            callback: function ($$v) {
              _vm.pilihbarang = $$v;
            },
            expression: "pilihbarang"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref19) {
        var item = _ref19.item;
        return [_vm._v(" " + _vm._s(item ? item.kode : "-") + " ")];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref20) {
        var item = _ref20.item;
        return [_vm._v(" " + _vm._s(item ? item.varian : "-")), _c('br'), _c('small', [_vm._v(" " + _vm._s(item ? item.nama : "-") + " ")])];
      }
    }, {
      key: "cell(blok)",
      fn: function (_ref21) {
        var item = _ref21.item;
        return [_vm._v(" " + _vm._s(item.blok ? item.blok.blok : "-") + " ")];
      }
    }, {
      key: "cell(palet)",
      fn: function (_ref22) {
        var item = _ref22.item;
        return [_vm._v(" " + _vm._s(item.palet ? item.palet.palet : "-") + " ")];
      }
    }, {
      key: "cell(rak)",
      fn: function (_ref23) {
        var item = _ref23.item;
        return [_vm._v(" " + _vm._s(item.rak ? item.rak.rak : "-") + " ")];
      }
    }, {
      key: "cell(laci)",
      fn: function (_ref24) {
        var item = _ref24.item;
        return [_vm._v(" " + _vm._s(item.laci ? item.laci.laci : "-") + " ")];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref25) {
        var item = _ref25.item;
        return [_vm._v(" " + _vm._s(item.jumlah ? item.jumlah : "-") + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref26) {
        var item = _ref26.item;
        return [_vm._v(" " + _vm._s(item.konversi_ket) + " ")];
      }
    }], null, false, 505436461)
  }) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRowsBarangs,
      "per-page": _vm.perPageBarangs,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPageBarangs,
      callback: function ($$v) {
        _vm.currentPageBarangs = $$v;
      },
      expression: "currentPageBarangs"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Tentukan Penyimpanan"
    }
  }, [_c('form', [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "gudang",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref27) {
        var errors = _ref27.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Gudang")]), _c('b-form-select', {
          attrs: {
            "options": _vm.dataGudang,
            "required": !_vm.form.gudang_id,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.form.gudang_id,
            callback: function ($$v) {
              _vm.$set(_vm.form, "gudang_id", $$v);
            },
            expression: "form.gudang_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1867791087)
  })], 1), _c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "blok",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref28) {
        var errors = _ref28.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Blok")]), _c('b-form-select', {
          attrs: {
            "options": _vm.blok_id,
            "required": !_vm.form.blok_id,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.form.blok_id,
            callback: function ($$v) {
              _vm.$set(_vm.form, "blok_id", $$v);
            },
            expression: "form.blok_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1192227885)
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Pallet")])]), _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Rak (optional)")]), _c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.rak_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.rak_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rak_id", $$v);
      },
      expression: "form.rak_id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Laci (optional)")]), _c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.laci_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.laci_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "laci_id", $$v);
      },
      expression: "form.laci_id"
    }
  })], 1), _c('b-form-group', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addBarang($event);
      }
    }
  }, [_vm._v("Add")])], 1)], 1)])], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-table', {
    attrs: {
      "fields": _vm.fieldsAddedItems,
      "bordered": "",
      "striped": "",
      "responsive": "",
      "items": _vm.addedItems
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(lokasi)",
      fn: function (_ref29) {
        var item = _ref29.item;
        return [_c('strong', [_vm._v(" " + _vm._s(item.lokasi) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function (row) {
        return [_c('b-link', {
          staticClass: "text-danger",
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.addedItems.splice(row.index, 1);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "24",
            "icon": "XCircleIcon"
          }
        })], 1)];
      }
    }], null, false, 3299828433)
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.allowCreate() || _vm.allowUpdate() ? _c('b-modal', {
    attrs: {
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": 'Ubah Barang Penyimpanan',
      "size": "lg"
    },
    model: {
      value: _vm.showModalBrg,
      callback: function ($$v) {
        _vm.showModalBrg = $$v;
      },
      expression: "showModalBrg"
    }
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_vm._v(" " + _vm._s(this.ubah ? this.ubah.barang ? this.ubah.barang.nama : 0 : 0) + " "), _c('b-card', {
    staticClass: "shadow-none",
    attrs: {
      "border-variant": "primary",
      "title": "Barang",
      "bg-variant": "transparent"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "Cari Barang"
    }
  }, [_vm._v("Cari Barang")]), _c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getDataBarangs();
      }
    },
    model: {
      value: _vm.caribarang,
      callback: function ($$v) {
        _vm.caribarang = $$v;
      },
      expression: "caribarang"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.getDataBarangs();
      }
    }
  }, [_vm._v(" Cari ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_vm.loaded ? _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPageBarangs,
      "items": _vm.dataBarangs,
      "fields": _vm.fieldsBarangs,
      "sort-by": _vm.sortByBarangs,
      "sort-desc": _vm.sortDescBarangs,
      "sort-direction": _vm.sortDirectionBarangs,
      "filter-included-fields": _vm.filterOnBarangs
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortByBarangs = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortByBarangs = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDescBarangs = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDescBarangs = $event;
      },
      "filtered": _vm.onFilteredBarangs
    },
    scopedSlots: _vm._u([{
      key: "cell(penyimpanan)",
      fn: function (data) {
        return [_c('b-form-checkbox', {
          attrs: {
            "value": data.item
          },
          model: {
            value: _vm.pilihbarang2,
            callback: function ($$v) {
              _vm.pilihbarang2 = $$v;
            },
            expression: "pilihbarang2"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref30) {
        var item = _ref30.item;
        return [_vm._v(" " + _vm._s(item.kode ? item.kode : "-") + " ")];
      }
    }, {
      key: "cell(harga_dasar)",
      fn: function (_ref31) {
        var item = _ref31.item;
        return [_vm._v(" " + _vm._s(item.harga_dasar ? _vm.formatRupiah(item.harga_dasar) : "-") + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref32) {
        var item = _ref32.item;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : "-") + " ")];
      }
    }], null, false, 1639154893)
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submitbrg($event);
      }
    }
  }, [_vm._v(" Ubah Barang Penyimpanan ")])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.allowCreate() || _vm.allowUpdate() ? _c('b-modal', {
    attrs: {
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": 'Ubah Lokasi Penyimpanan',
      "size": "lg"
    },
    model: {
      value: _vm.showModalPen,
      callback: function ($$v) {
        _vm.showModalPen = $$v;
      },
      expression: "showModalPen"
    }
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_vm._v(" " + _vm._s(this.ubahpen ? this.ubahpen.barang ? this.ubahpen.barang.nama : 0 : 0) + " "), _c('b-card', {
    attrs: {
      "title": "Buat Penyimpanan Baru",
      "border-variant": "primary"
    }
  }, [_c('validation-observer', {
    ref: "form2"
  }, [_c('form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "blok",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref33) {
        var errors = _ref33.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Blok")]), _c('b-form-select', {
          attrs: {
            "options": _vm.blok_id,
            "required": !_vm.ubahpen.blok_id,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.ubahpen.blok_id,
            callback: function ($$v) {
              _vm.$set(_vm.ubahpen, "blok_id", $$v);
            },
            expression: "ubahpen.blok_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1446863533)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Palet")]), _c('b-form-select', {
    attrs: {
      "options": _vm.palet_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.ubahpen.palet_id,
      callback: function ($$v) {
        _vm.$set(_vm.ubahpen, "palet_id", $$v);
      },
      expression: "ubahpen.palet_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Rak (optional)")]), _c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.rak_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.ubahpen.rak_id,
      callback: function ($$v) {
        _vm.$set(_vm.ubahpen, "rak_id", $$v);
      },
      expression: "ubahpen.rak_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Laci (optional)")]), _c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.laci_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.ubahpen.laci_id,
      callback: function ($$v) {
        _vm.$set(_vm.ubahpen, "laci_id", $$v);
      },
      expression: "ubahpen.laci_id"
    }
  })], 1)], 1)], 1)], 1)])], 1), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submitpen($event);
      }
    }
  }, [_vm._v(" Ubah Lokasi Penyimpanan ")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }