<template>
  <div>
    <div class="running-text-container mb-1">
      <div class="running-text">
        <!-- Selamat datang di website kami! Nikmati pengalaman terbaik bersama kami! -->
        Mohon maaf sebelumnya, fitur ini belum berfungsi (masih layout) karena
        masih tahap analisis sama tim. Terima kasih
      </div>
    </div>
    <!-- <b-container> -->
    <b-row>
      <b-col cols="12" md="6">
        <!-- Label Design -->
        <h4>Label Design</h4>
        <b-card>
          <b-form-group label="Select Label Design:">
            <!-- <b-tabs v-model="activeTab">
              <b-tab title="QR"> -->
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-around;
              "
            >
              <b-form-radio v-model="selectlabel" value="exqr">
                <img
                  src="./exqr.png"
                  style="width: 75%"
                  alt="QR"
                  class="img-fluid"
                />
              </b-form-radio>
              <b-form-radio v-model="selectlabel" value="exbar">
                <img
                  src="./exbar.png"
                  style="width: 75%"
                  alt="Barcode"
                  class="img-fluid"
                />
              </b-form-radio>
            </div>
            <!-- </b-tab>
            </b-tabs> -->
          </b-form-group>

          <!-- Table for Items -->
          <b-row>
            <b-col cols="12">
              <h4>Select Items</h4>
              <!-- <b-table :items="items" :fields="tableFields" bordered>
                <template #cell(copies)="row">
                  <b-form-input type="number" v-model="row.item.copies" min="1" max="100" />
                </template>
</b-table> -->
              <b-row>
                <b-col lg="6" class="my-1">
                  <!-- <b-form-group :label="$t('Filter')" label-for="filter-input" label-size="sm" class="mb-0"> -->
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        size="sm"
                        :disabled="!filter"
                        @click="filter = ''"
                        >{{ $t("Clear") }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <!-- </b-form-group> -->
                </b-col>
                <b-col lg="6" class="my-1">
                  <b-button
                    size="sm"
                    variant="primary"
                    block
                    @click.prevent="chooseBarang"
                    >Add Bulk</b-button
                  >
                  <!-- <b-form-group v-model="sortDirection" label="Filter On"
                description="Leave all unchecked to filter on all data" label-cols-sm="3" label-align-sm="right"
                label-size="sm" class="mb-0" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group v-model="filterOn" :aria-describedby="ariaDescribedby" class="mt-1">
                  <b-form-checkbox value="name">Name</b-form-checkbox>
                  <b-form-checkbox value="age">Age</b-form-checkbox>
                  <b-form-checkbox value="isActive">Active</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group> -->
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table
                    v-if="selectedBarangs.length > 0"
                    responsive
                    :fields="fields"
                    :items="selectedBarangs"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                  >
                    <template #table-colgroup="{ fields }">
                      <col
                        v-for="field in fields"
                        :key="field.key"
                        :style="{ width: field.key == 'no' ? '20px' : '' }"
                      />
                    </template>
                    <template #cell(no)="{ index }">
                      {{ 1 + index }}
                    </template>
                    <template #cell(kode)="{ item }">
                      {{ item.kode ? item.kode : "-" }}<br />
                      <small>{{ item.varian_item[0].no_batch }}</small>
                    </template>
                    <template #cell(kategori)="{ item }">
                      {{ item.kategori ? item.kategori.kategori : "-" }}
                    </template>
                    <template #cell(no_container)="{ item }">
                      <b-form-input
                        v-model="item.no_container"
                        placeholder="Ex: MRT123213"
                      ></b-form-input>
                    </template>
                    <template #cell(nama)="{ item }">
                      <span v-if="item">
                        {{ item.varian_item[0].varian }}<br />
                        <small>{{ item.kategori.kategori }}</small> /
                        <small>{{ item.nama }}</small>
                      </span>
                      <i v-else class="text-danger">Barang tidak ada.</i>
                    </template>
                    <template #cell(no_seal)="{ item }">
                      <b-form-input
                        v-model="item.no_seal"
                        placeholder="Ex: GR123"
                      ></b-form-input>
                    </template>
                    <template #cell(harga_beli)="{ item }">
                      <div>{{ formatRupiah(item.harga_beli) }}</div>
                      <strong
                        class="text-danger"
                        v-if="item.diskon && item.diskon > 0"
                      >
                        diskon {{ formatRupiah(item.diskon) }}
                      </strong>
                    </template>
                    <template #cell(diskon)="{ item }">
                      {{ formatRupiah(item.diskon) }}
                    </template>
                    <template #cell(qty)="{ item }">
                      <b-form-input
                        size="sm"
                        type="number"
                        v-model="item.qty"
                      ></b-form-input>
                      /{{ item.satuan ? item.satuan.satuan : "" }}
                    </template>
                    <template #cell(total)="{ item }">
                      {{
                        formatRupiah(
                          parseInt(item.harga_beli) * parseInt(item.qty)
                        )
                      }}
                    </template>
                    <template #cell(action)="{ item, index }">
                      <feather-icon
                        icon="MinusCircleIcon"
                        size="24"
                        class="text-danger cursor-pointer"
                        @click="removeBarang(item, index)"
                      ></feather-icon>
                    </template>
                  </b-table>
                  <div v-else class="alert alert-danger p-2">
                    Harap pilih produk yang akan di print Barcode/QR Code
                  </div>
                  <!-- <div class="d-flex justify-content-end align-items-center mt-3 px-2">
                    <h4>Total Pembelian:</h4>
                    <h2 class="ml-2">Rp {{ formatRupiah(totalPembelian) }}</h2>
                  </div> -->
                  <barang-modal @submit="onChooseBarang"></barang-modal>
                </b-col>
              </b-row>

              <!-- Generate Button -->
              <b-row>
                <b-col cols="12" class="text-right">
                  <b-button variant="primary" @click="generatePDF"
                    >Generate PDF</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="12" md="6">
        <!-- PDF Viewer -->
        <h4>PDF Viewer</h4>
        <b-card>
          <!-- <iframe type="iframe" src="https://api-heykama.notive.my.id/v2/print-label?barang_id=2399&custom_text=OK" style="height: 100vh;" /> -->
          <PDFViewer :source="pdfSrc" style="height: 100vh" />
          <!-- @download="handleDownload" -->
        </b-card>
      </b-col>
    </b-row>

    <!-- </b-container> -->
  </div>
</template>

<script>
// import { pdfApp } from "vue-pdf-app";
import BarangModal from "./components/Modal.vue";
import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";

import {
  BTable,
  BCard,
  BEmbed,
  VBTooltip,
  BImg,
  BForm,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
  BOverlay,
  BFormTags,
  BFormText,
  BFormInvalidFeedback,
  BFormSpinbutton,
  BButtonGroup,
  BTabs,
  BTab,
  BContainer,
  BFormRadio,
} from "bootstrap-vue";

export default {
  components: {
    BarangModal,
    BEmbed,
    PDFViewer,
    BTable,
    BContainer,
    BFormRadio,
    BCard,
    VBTooltip,
    BImg,
    BForm,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BOverlay,
    BFormTags,
    BFormText,
    BFormInvalidFeedback,
    BFormSpinbutton,
    BButtonGroup,
    BTabs,
    BTab,
  },
  data() {
    return {
      filter: null,
      filterOn: [],
      fields: [
        { key: "kode", label: "Barcode" },
        { key: "nama", label: "Nama Barang" },
        { key: "qty", label: "Copies" },
        { key: "action", label: "" },
      ],
      selectedBarangs: [],
      selectlabel: "exbar",
      activeTab: 0,
      pdfSrc: "/assets/pdf/sample.pdf", // Replace with your PDF file path
      items: [
        { item: "-AR -0.25", price: "Rp8,000.00", copies: 1 },
        { item: "-AR -0.50", price: "Rp8,000.00", copies: 1 },
        { item: "-AR -0.75", price: "Rp8,000.00", copies: 1 },
      ],
      tableFields: [
        { key: "item", label: "Item" },
        { key: "price", label: "Price" },
        { key: "copies", label: "Copies" },
      ],
    };
  },
  methods: {
    onChooseBarang(selectedBarangs) {
      selectedBarangs.map((barang) => {
        barang.no_container = null;
        barang.no_seal = null;
        this.selectedBarangs.push(barang);
      });
      this.$bvModal.hide("barang-modal");
    },

    chooseBarang() {
      this.$bvModal.show("barang-modal");
    },
    generatePDF() {
      alert("PDF generation logic here!");
      // Add your PDF generation logic here
    },
  },
};
</script>

<style>
body {
  font-family: Arial, sans-serif;
}
</style>
<style scoped>
.running-text-container {
  overflow: hidden;
  /* Hides the text that goes beyond the container */
  white-space: nowrap;
  /* Prevents text from wrapping */
  width: 100%;
  /* Full width */
  background-color: #f8f9fa;
  /* Optional: background color */
  padding: 10px 0;
  /* Optional: padding for visual enhancement */
  border: 1px solid #ddd;
  /* Optional: border */
}

.running-text {
  display: inline-block;
  /* Text behaves like an inline element */
  animation: marquee 10s linear infinite;
  /* Runs the marquee animation */
  font-size: 1.2rem;
  /* Optional: font size */
  color: #333;
  /* Optional: text color */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>
