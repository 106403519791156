<template>
  <b-overlay :show="loading">
    <b-row>
      <b-col>
        <b-card no-body>
          <b-row>
            <!-- <b-card-body> -->
            <b-col xl="6" lg="6" md="6" sm="6">
              <b-card no-body header-bg-variant="success" header-text-variant="white" header-tag="header">
                <template #header>
                  <h3 class="mb-0 text-white"><b>{{ $t('Supplier Information') }}</b></h3>
                </template>
                <hr style="height: 3px; background-color: black; border: none;">
                <!-- <h4 class="mb-2">Informasi Pembelian</h4> -->
                <b-card-body>
                  <b-row class="">
                    <b-col cols="12">
                      <b-form-group label="Purchase Order (PO) No." label-for="h-po" label-cols-md="4">
                        <b-form-input id="h-po" v-model="form.no"></b-form-input>
                        <small><i>Jika ingin otomatis, maka kosongkan form</i></small>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label-for="h-supplier" label-cols-md="4">
                        <template #label>
                          Supplier <span class="text-danger"><small>(*)</small></span>
                        </template>
                        <v-select id="h-supplier" :options="suppliers" placeholder="-- Select Supplier --" label="text"
                          v-model="supplier" @change="fillForm"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Address" label-for="h-address" label-cols-md="4">
                        <b-form-input v-model="supplier.alamat"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Phone/HP" label-for="h-phone" label-cols-md="4">
                        <b-form-input v-model="supplier.nohp"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Email" label-for="h-email" label-cols-md="4">
                        <b-form-input v-model="supplier.email"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="PIC" label-for="h-nama" label-cols-md="4">
                        <b-form-input v-model="supplier.nama"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Category" label-for="h-category" label-cols-md="4">
                        <template #label>
                          Category <span class="text-danger"><small>(*)</small></span>
                        </template>
                        <v-select :options="[
                          { value: 'Frame', text: 'Frame' },
                          { value: 'Lensa', text: 'Lensa' },
                          { value: 'Packaging', text: 'Packaging' },
                          { value: 'Accs', text: 'Accs' },
                        ]" label="text" v-model="form.kategori"></v-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col xl="6" lg="6" md="6" sm="6">
              <b-card no-body class="mb-2" header-bg-variant="success" header-text-variant="white" header-tag="header">
                <template #header>
                  <h3 class="mb-0 text-white"><b>{{ $t('Shipment Information') }}</b></h3>
                </template>
                <hr style="height: 3px; background-color: black; border: none;">
                <!-- <h4 class="mb-2">Informasi Pembelian</h4> -->
                <b-card-body>
                  <b-row class="">
                    <b-col cols="12">
                      <b-form-group label-for="h-po-date" label-cols-md="4">
                        <template #label>Purchase Order (PO) Date
                          <!-- <small><span
                        class="text-danger">(*)</span></small> -->
                        </template>
                        <b-form-input v-model="form.tanggal" type="date" class="w-full" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label-for="h-required-date" label-cols-md="4">
                        <template #label>Required Date
                          <!-- <small><span class="text-danger">(*)</span></smxall> -->
                        </template>
                        <b-form-input v-model="form.tanggal_kirim" type="date" class="w-full" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label-for="h-eta-date" label-cols-md="4">
                        <template #label>Estimated Time Arrival (ETA)
                          <!-- <small><span
                        class="text-danger">(*)</span></small> -->
                        </template>
                        <b-form-input v-model="form.tanggal_terima" type="date" class="w-full" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Ship By" label-for="h-ship-by" label-cols-md="4">
                        <b-form-input v-model="form.ship_by"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Shipping Cost" label-for="h-shipcost" label-cols-md="4">
                        <b-input-group prepend="Rp ">
                          <b-form-input @keyup="doFormatRupiah1()" v-model="form.shipping_cost"></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="VAT (percent)" label-for="h-vat" label-cols-md="4">
                        <div class="d-flex align-items-center">
                          <b-form-input id="h-vat" v-model="form.vat_11" type="number" placeholder="VAT"
                            style="width: 10ch;"></b-form-input>
                          <span style="margin-left: 0.5rem; margin-right: 1.5rem;"> %</span>
                          <span>=</span>
                          <!-- Simbol persen dengan jarak -->
                          <span class="ml-auto">Rp {{ formatRupiah(vatAmount) }}</span> <!-- Hasil perhitungan -->
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Category" label-for="h-category" label-cols-md="4">
                        <template #label>
                          Notes
                          <!-- <span class="text-danger"><small>(*)</small></span> -->
                        </template>
                        <!-- <label for="">Keterangan <small><span class="text-info">(opsional)</span></small></label> -->
                        <b-form-textarea v-model="form.keterangan"></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
                <!-- <h4 class="mb-2">Informasi Pembelian</h4> -->

                <!-- <b-row class="mb-4">
            <b-col sm="12" md="3" class="mb-2">
              <label for="">Pilih Supplier <small><span class="text-danger">(*)</span></small></label>
              <v-select :options="suppliers" label="text" v-model="supplier"></v-select>
            </b-col>
            <b-col sm="12" md="3" class="mb-2">
              <label for="">No PO <small><span class="text-info">(opsional)</span></small></label>
              <b-form-input v-model="form.no"></b-form-input>
              <small><i>Jika ingin otomatis, maka kosongkan form</i></small>
            </b-col>
            <b-col md="3" class="mb-2">
              <label for="">Tanggal Pembelian <small><span class="text-danger">(*)</span></small></label>
              <b-form-input v-model="form.tanggal" type="date" class="w-full" />
            </b-col>
            <b-col md="3" class="mb-2">
              <label for="">Tanggal Perkiraan Datang <small><span class="text-info">(opsional)</span></small></label>
              <b-form-input v-model="form.jatuh_tempo" type="date" class="w-full" />
            </b-col>
            <b-col sm="12" md="3" class="mb-2">
              <label for="">Choose Category <small><span class="text-danger">(*)</span></small></label>
              <v-select :options="[
                { value: 'Frame', text: 'Frame' },
                { value: 'Lensa', text: 'Lensa' },
                { value: 'Packaging', text: 'Packaging' },
                { value: 'Accs', text: 'Accs' },
              ]" label="text" v-model="form.kategori"></v-select>
            </b-col>
            <b-col md="9">
              <label for="">Keterangan <small><span class="text-info">(opsional)</span></small></label>
              <b-form-textarea v-model="form.keterangan"></b-form-textarea>
            </b-col>
          </b-row> -->
              </b-card>
            </b-col>
          </b-row>

          <b-row>
            <b-col xl="12" lg="12" md="12" sm="12">
              <b-card no-body header-bg-variant="success" header-text-variant="white" header-tag="header">
                <template #header>
                  <h3 class="mb-0 text-white"><b>{{ $t('Item(s) Details') }}</b></h3>
                </template>
                <hr style="height: 3px; background-color: black; border: none;">
                <!-- <h4 class="mb-2">Informasi Pembelian</h4> -->
                <b-card-body>
                  <!-- <h4 class="mb-2">
                    {{ $t('Item Details
') }} <span v-if="selectedBarangs.length > 0">({{ selectedBarangs.length
                      }})</span>
                  </h4> -->

                  <import-barang @getId="getId"></import-barang>
                  <b-row>
                    <b-col lg="2">
                      <!-- <b-button-group> -->
                      <b-button block variant="warning" class="mb-1 mr-1" @click.prevent="chooseBarang">
                        <feather-icon icon="PlusIcon" class="mr-1" />
                        {{ ('Add Product')
                        }}</b-button>
                    </b-col>
                    <b-col lg="1">
                      <b-button block variant="primary" class="mb-1 mr-1" @click.prevent="chooseImport">{{ ('Import')
                        }}</b-button>
                      <!-- </b-button-group> -->
                    </b-col>
                    <b-col lg="7">
                      <!-- <b-form-group :label="$t('Filter')" label-for="filter-input" label-size="sm" class="mb-0"> -->
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-button variant="success">by Product</b-button>
                        </b-input-group-prepend>
                        <!-- <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text class="d-flex align-items-center">
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>

                </b-input-group> -->
                        <b-form-input id="filter-input" v-model="filter" type="search"
                          placeholder='Press "enter" to search'></b-form-input>
                        <b-input-group-append>
                          <b-button variant="primary" :disabled="!filter" @click="filter = ''">{{ $t('Clear')
                            }}</b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <!-- </b-form-group> -->
                    </b-col>
                    <b-col cols="2">
                      <b-button variant="outline-warning" block>
                        <span class="text-warning">Total : {{ selectedBarangs.length }}</span>
                        <!-- <h5 class="text-warning">
                    Selected : {{ selectedginee.length }}
                  </h5> -->
                      </b-button>
                    </b-col>
                    <!-- <b-col lg="6" class="my-1">
              <b-form-group v-model="sortDirection" label="Filter On"
                description="Leave all unchecked to filter on all data" label-cols-sm="3" label-align-sm="right"
                label-size="sm" class="mb-0" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group v-model="filterOn" :aria-describedby="ariaDescribedby" class="mt-1">
                  <b-form-checkbox value="name">Name</b-form-checkbox>
                  <b-form-checkbox value="age">Age</b-form-checkbox>
                  <b-form-checkbox value="isActive">Active</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col> -->
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-table v-if="selectedBarangs.length > 0" responsive :fields="fields" :items="selectedBarangs"
                        :filter="filter" :filter-included-fields="filterOn">
                        <template #table-colgroup="{ fields }">
                          <col v-for="field in fields" :key="field.key"
                            :style="{ width: field.key == 'no' ? '20px' : '' }" />
                        </template>
                        <template #cell(no)="{ index }">
                          {{ 1 + index }}
                        </template>
                        <template #cell(kode)="{ item }">
                          {{ item.kode ? item.kode : '-' }}<br>
                          <small>{{ item.varian_item[0].no_batch }}</small>
                        </template>
                        <template #cell(kategori)="{ item }">
                          {{ item.kategori ? item.kategori.kategori : '-' }}
                        </template>
                        <template #cell(no_container)="{ item }">
                          <b-form-input v-model="item.no_container" placeholder="Ex: MRT123213"></b-form-input>
                        </template>
                        <template #cell(nama)="{ item }">
                          <span v-if="item">
                            {{ item.varian_item[0].varian }}<br>
                            <small>{{ item.kategori.kategori }}</small> /
                            <small>{{ item.nama }}</small>
                          </span>
                          <i v-else class="text-danger">Barang tidak ada.</i>
                        </template>
                        <template #cell(no_seal)="{ item }">
                          <b-form-input v-model="item.no_seal" placeholder="Ex: GR123"></b-form-input>
                        </template>
                        <template #cell(harga_beli)="{ item }">
                          <div>{{ formatRupiah(item.harga_beli) }}</div>
                          <strong class="text-danger" v-if="item.diskon && item.diskon > 0"> diskon {{
                            formatRupiah(item.diskon)
                          }}
                          </strong>
                        </template>
                        <template #cell(diskon)="{ item }">
                          {{ formatRupiah(item.diskon) }}
                        </template>
                        <template #cell(qty)="{ item, index }">
                          <!-- <b-input-group :append="item.satuan ? '/ ' + item.satuan.satuan : ''">
                    <b-form-input type="number" v-model="item.qty"></b-form-input>
                  </b-input-group> -->
                          <!-- <b-input-group :append="item.satuan ? '/ ' + item.satuan.satuan : ''"> -->
                          <div class="d-flex align-items-center">
                            <b-form-input @keyup="doFormatRupiah(index)" id="h-qtyitem" v-model="item.qty"
                              style="width: 10ch;"></b-form-input>
                            <span style="margin-left: 0.5rem; margin-right: 0.5rem;">/</span>
                            <span> {{ item.satuan ?
                              item.satuan.satuan :
                              "" }} </span>
                          </div>
                        </template>
                        <!-- <template #cell(total)="{ item, i }">
                          {{ formatRupiah(parseInt(unFormatRupiah(item.harga_beli)) *
                            parseInt(unFormatRupiah(item.qty))) }}
                          {{ formatRupiah(calculateTotal(index)) }}
                        </template> -->
                        <template #cell(action)="{ item, index }">
                          <feather-icon icon="MinusCircleIcon" size="24" class="text-danger cursor-pointer"
                            @click="removeBarang(item, index)"></feather-icon>
                        </template>
                      </b-table>
                      <div v-else class="alert alert-danger p-2">{{$t("Please select item")}}</div>
                      <hr>
                      <!-- <div class="d-flex justify-content-end align-items-center mt-3 px-2">
                
              </div> -->
                    </b-col>
                    <b-col cols="8">
                      <!-- {{ selectedBarangs }} -->
                    </b-col>
                    <b-col cols="4">
                      <div class=" px-2">
                        <!-- TOTAL -->
                        <b-row class="py-1 border-bottom align-items-center" style="border-width: 3px;">
                          <b-col class="text-right">
                            <h5 class="font-weight-bold"><b>TOTAL</b></h5>
                          </b-col>
                          <b-col>
                            <h5 class="font-weight-bold">:</h5>
                          </b-col>
                          <b-col class="text-right">
                            <h5><b>Rp {{ formatRupiah(totalPembelian) }}</b></h5>
                          </b-col>
                        </b-row>

                        <!-- Shipping Cost -->
                        <b-row class="py-1 border-bottom align-items-center">
                          <b-col class="text-right">
                            <h5>Shipping Cost</h5>
                          </b-col>
                          <b-col>
                            <h5 class="font-weight-bold">:</h5>
                          </b-col>
                          <b-col class="text-right">
                            <h5>Rp {{ formatRupiah(form.shipping_cost) }}</h5>
                          </b-col>
                        </b-row>

                        <!-- VAT -->
                        <b-row class="py-1 border-bottom align-items-center">
                          <b-col class="text-right">
                            <h5>VAT</h5>
                          </b-col>
                          <b-col>
                            <h5 class="font-weight-bold">:</h5>
                          </b-col>
                          <b-col class="text-right">
                            <h5>Rp {{ formatRupiah(vatAmount) }}</h5>
                          </b-col>
                        </b-row>
                        <!-- GRAND TOTAL -->
                        <b-row class="py-1 border align-items-center border-warning" style="border-width: 3px;">
                          <b-col class="text-right">
                            <h5 class="font-weight-bold text-uppercase"><b>GRAND TOTAL</b></h5>
                          </b-col>
                          <b-col>
                            <h5 class="font-weight-bold">:</h5>
                          </b-col>
                          <b-col class="text-right">
                            <h5 class="font-weight-bold"><b>Rp {{ formatRupiah(grandTotal) }}</b></h5>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                    <b-col class="mt-1">
                      <barang-modal @submit="onChooseBarang"></barang-modal>
                      <div class="d-flex justify-content-end">
                        <b-button :disabled="!isValidForm" @click.prevent="submit"
                          :variant="!isValidForm ? 'secondary' : 'warning'">{{ $t('Save') }}</b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <!-- </b-card-body> -->
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import BarangModal from "./components/Modal.vue";
import ImportBarang from "./ImportBarang.vue";
import vSelect from "vue-select";
import {
  BButtonGroup, BCardBody, BCardText, BCardTitle,
  BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormGroup, BFormCheckbox, BFormCheckboxGroup, BCard, BButton, BRow, BOverlay, BTable, BCol, BFormInput, BFormSelect, BFormTextarea
} from "bootstrap-vue";
export default {
  components: {
    ImportBarang,
    BarangModal, BCardBody, BCardText, BCardTitle,
    BButtonGroup, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormGroup, BFormCheckbox, BFormCheckboxGroup, BCard,
    BOverlay,
    BButton,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    vSelect,
  },
  data: () => ({
    //Data Barang

    pageOptions: [10, 20, 50, 100],
    sortBy: null,
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    perPage: 10,
    totalRows: 1,
    currentPage: 1,
    fieldsbarang: [
      // { key: "show_detail", label: "#" },
      { key: "no", label: "No", sortable: true },
      { key: "kode", label: "Barcode" },
      { key: "nama", label: "Nama Produk", sortable: true },
      // { key: "id_satuan", label: "Satuan" },
      { key: "action", label: "#" },
    ],
    // supplier: null, // for selected supplier
    fields: [
      // { key: "no_container", label: "No. Container" },
      // { key: "no_seal", label: "No. Seal" },
      // { key: "kategori", label: "kategori" },
      { key: "kode", label: "Barcode" },
      { key: "nama", label: "Nama Barang" },
      { key: "harga_beli", label: "Harga Beli" },
      { key: "qty", label: "Qty" },
      { key: "total", label: "Total" },
      { key: "action", label: "" },
    ],
    selectedBarangs: [],
    form: {
      tanggal: null,
      no: null,
      jenis: 2,
      kategori: 0,
      id_kas: null,
      id_supplier: null,
      keterangan: null,
      vat_11: 0,
      shipping_cost: 0,
      ship_by: null,
      tanggal_kirim: null,
      tanggal_terima: null,
      kategori: {
        value: 'Frame',
        text: 'Frame'
      }
    },
    suppliers: [],
    barangs: [],
    loading: false,
    hasPermissionApprove: false,
    supplier: {
      value: null,
      text: '-- Select Supplier --',
      nama: null,
      nohp: null,
      email: null,
      alamat: null,
    }
  }),
  watch: {
    supplier(val) {
      this.supplier.alamat = val.alamat ? val.alamat : null;
    },
    // "item.qty"(item) {
    //   return parseInt(item.harga_beli) * parseInt(item.qty);
    // },
  },
  computed: {
    vatAmount() {
      return (((this.totalPembelian ? parseInt(this.totalPembelian) : 0) * (this.form.vat_11 / 100)));
    },
    isKas() {
      return this.akun && this.akun.kategori && this.akun.kategori.kategori == "Kas & Bank";
    },
    totalPembelian() {
      return this.selectedBarangs.reduce((total, barang) => {
        // Pastikan harga_beli, qty, dan diskon adalah angka murni
        const hargaBeli = parseFloat(this.unFormatRupiah(barang.harga_beli)) || 0;
        const qty = parseInt(this.unFormatRupiah(barang.qty)) || 0;
        const diskon = parseFloat(this.unFormatRupiah(barang.diskon)) || 0;

        // Hitung total pembelian dengan diskon
        return total + (hargaBeli * qty - diskon);
      }, 0);
      // return this.selectedBarangs.reduce((total, barang) => (total += barang.harga_beli * barang.qty - barang.diskon), 0);
    },
    grandTotal() {
      return parseFloat(this.totalPembelian) + parseFloat(this.unFormatRupiah(this.form.shipping_cost)) + parseFloat(this.vatAmount)
    },
    isValidForm() {
      if (!this.form.tanggal || !this.supplier || this.selectedBarangs.length < 1) {
        return false;
      }

      return true;
    },
    updateMode() {
      if (this.$route.params.id) {
        return true;
      }
      return false;
    },
  },
  methods: {
    calculateTotal(index) {
      const item = this.selectedBarangs[index];

      // Pastikan item dan harga_beli ada
      if (item && item.harga_beli && item.qty) {
        const qty = parseInt(item.qty) || 0;
        const hargaBeli = parseInt(item.harga_beli) || 0;
        return hargaBeli * qty;
      }
      return 0; // Mengembalikan 0 jika item atau harga_beli/qty tidak ada
    },
    doFormatRupiah1() {
      this.form.shipping_cost = this.formatRupiah(this.form.shipping_cost)
    },
    doFormatRupiah(i) {
      // Pastikan `this.selectedBarangs` ada dan memiliki item di indeks `i`
      if (this.selectedBarangs && this.selectedBarangs[i]) {
        const barangs = [...this.selectedBarangs]; // Membuat salinan dangkal dari selectedBarangs

        // Format qty untuk tampilan, tetapi simpan nilai numerik yang tidak diformat
        const formattedQty = this.formatRupiah(barangs[i].qty);
        const unformattedQty = this.unFormatRupiah(formattedQty); // Un-format qty untuk perhitungan dan penyimpanan
        this.$set(barangs, i, { ...barangs[i], qty: formattedQty }); // Simpan nilai numerik tanpa format

        // Format harga_beli untuk tampilan, tetapi simpan nilai numerik yang tidak diformat
        const formattedHargaBeli = this.formatRupiah(barangs[i].harga_beli);
        const unformattedHargaBeli = this.unFormatRupiah(formattedHargaBeli); // Un-format harga_beli
        this.$set(barangs, i, { ...barangs[i], harga_beli: formattedHargaBeli });

        // Hitung total berdasarkan nilai yang tidak diformat
        const total = this.formatRupiah(parseInt(unformattedHargaBeli) * parseInt(unformattedQty));
        this.$set(barangs, i, { ...barangs[i], total });

        // Menetapkan kembali array yang sudah diperbarui ke selectedBarangs
        this.selectedBarangs = barangs;
      } else {
        console.error("Data item tidak ditemukan pada indeks: " + i);
      }

    },
    fillForm() {
      const supplierData = this.suppliers[this.selectedSupplier];
      if (supplierData) {
        // Isi form dengan data supplier
        this.supplier.alamat = supplierData.alamat;
        this.supplier.nohp = supplierData.nohp;
        this.supplier.email = supplierData.email;
        this.supplier.nama = supplierData.nama;
        // this.supplier.category = supplierData.category;
      } else {
        // Kosongkan supplier jika tidak ada supplier yang dipilih
        this.supplier = {
          alamat: "",
          nohp: "",
          email: "",
          nama: "",
          // category: ""
        };
      }
    },
    async checkPermission() {
      const menu = await this.currentMenu(this.$route.meta.parent);
      const params = {
        level_id: this.user.level.id,
      };
      if (menu) {
        params.menu_id = menu.id;
      }
      this.listStatus = await this.$store.dispatch("statusrole/getData", params);

      // data_status => 1 approve gm, data_status => 2 pay finance
      const hasPermissionApprove = this.listStatus.some((status) => status.data_status == 1);

      this.hasPermissionApprove = hasPermissionApprove;
    },
    async submit() {
      if (!this.isValidForm) {
        this.displayError({
          message: "Harap lengkapi form pembelian!",
        });
        return false;
      }

      const payload = {
        no: this.form.no,
        jenis: this.form.jenis ? this.form.jenis : 2,
        kategori: this.form.kategori ? this.form.kategori.value : 'frame',
        tanggal: this.form.tanggal,
        id_supplier: this.supplier.value,
        tanggal_kirim: this.form.tanggal_kirim,
        tanggal_terima: this.form.tanggal_terima,
        alamat: this.supplier.alamat,
        no_hp: this.supplier.nohp,
        email: this.supplier.email,
        pic: this.supplier.nama,
        ship_by: this.form.ship_by, // optional | ship by
        shipping_cost: this.unFormatRupiah(this.form.shipping_cost), // optional | ship cost
        vat_11: this.unFormatRupiah(this.vatAmount), // optional | vat 11%
        item: [],
      };

      if (this.form.keterangan) {
        payload.keterangan = this.form.keterangan;
      }
      // if (this.form.jatuh_tempo) {
      //   payload.jatuh_tempo = this.form.jatuh_tempo;
      // }
      if (this.updateMode) {
        payload.id = this.$route.params.id;
      }
      if (this.hasPermissionApprove) {
        payload.izin = 1;
      }

      // make payload item rincian barang
      this.selectedBarangs.map((barang) => {
        const payloadBarang = {
          id_barang: barang.id,
          harga_beli: this.unFormatRupiah(barang.harga_beli),
          qty: parseInt(barang.qty),
        };
        if (barang.rincian_id) {
          payloadBarang.id = barang.rincian_id;
        }
        // if (barang.varian) {
        //   payloadBarang.id = barang.varian_item[0].varian;
        // }
        if (barang.no_container) {
          payloadBarang.no_container = barang.no_container;
        }
        if (barang.no_seal) {
          payloadBarang.no_seal = barang.no_seal;
        }
        const diskon = parseInt(barang.diskon);
        if (diskon > 0) {
          payloadBarang.diskon = diskon;
        }
        if (this.updateMode) {
          payloadBarang.id_pembelian = this.$route.params.id;
        }

        payload.item.push(payloadBarang);
      });

      console.log('CEk', payload)

      // check jika ada harga dasar baru, apakah ingin update harga dasar baru
      try {
        this.loading = true;
        await this.$store.dispatch("pembelian/save", [payload]);
        if (this.updateMode) {
          await this.$store.dispatch("pembelian-rincian/save", payload.item);
        }

        this.loading = false;
        this.displaySuccess({
          message: "Pembelian berhasil",
        });
        const thereChangePrice = this.selectedBarangs.some((barang) => barang.isChangePrice == true);

        if (thereChangePrice) {
          this.updateBasePrice();
        } else {
          setTimeout(() => this.$router.push("/pembelian"), 500);
        }
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    updateBasePrice() {
      this.$swal({
        title: "Update harga dasar?",
        text: "Harga dasar barang yang anda pilih akan diupdate!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
      }).then((res) => {
        if (res.value) {
          const barangs = this.selectedBarangs.filter((barang) => barang.isChangePrice);
          const payloads = barangs.map((barang) => ({
            id: barang.id,
            kode: barang.kode,
            nama: barang.nama,
            no_batch: barang.no_batch ? barang.no_batch : barang.varian_item[0].no_batch,
            main_sku: barang.main_sku ? barang.main_sku : barang.varian_item[0].main_sku,
            varian: barang.varian ? barang.varian : barang.varian_item[0].varian,
            kategori: barang.kategori.kategori,
            jenis: barang.jenis,
            id_kategori: barang.kategori ? barang.kategori.id : null,
            id_satuan: barang.satuan ? barang.satuan.id : null,
            harga_dasar: barang.harga_dasar_baru,
          }));

          this.loading = true;
          this.$store
            .dispatch("barang/save", payloads)
            .then(() => {
              this.loading = false;
              setTimeout(() => this.$router.push("/pembelian"), 500);
            })
            .catch((e) => {
              this.loading = false;
              setTimeout(() => this.$router.push("/pembelian"), 500);
              this.displayError(e);
              return false;
            });
        } else {
          setTimeout(() => this.$router.push("/pembelian"), 500);
        }
      });
    },
    removeBarang(item, index) {
      if (!item.rincian_id) {
        this.selectedBarangs.splice(index, 1);
      } else {
        // remove via API
        const payload = {
          id: item.rincian_id,
          fungsi: 2,
        };
        this.$store
          .dispatch("pembelian-rincian/save", [payload])
          .then(() => this.selectedBarangs.splice(index, 1))
          .catch((e) => {
            this.displayError(e);
            return false;
          });
      }
    },
    onChooseBarang(selectedBarangs) {
      selectedBarangs.map((barang) => {
        barang.no_container = null;
        barang.no_seal = null;
        this.selectedBarangs.push(barang);
        console.log('cek', this.selectedBarangs)
      });
      this.$bvModal.hide("barang-modal");
    },
    chooseBarang() {
      this.$bvModal.show("barang-modal");
    },
    chooseImport() {
      this.$bvModal.show("import-barang");
    },
    // async getBarang() {
    //   // const jenis = this.isOwner ? 1 : 2
    //   // const payload = {
    //   //   order: "desc",
    //   //   start: 1,
    //   //   length: 10,
    //   // };
    //   const perPage = parseInt(this.perPage);
    //   const currentPage = (parseInt(this.currentPage) - 1) * perPage;
    //   const payload = {
    //     search: this.filter != null ? this.filter : null,
    //     order: "desc",
    //     start: currentPage,
    //     length: this.perPage,
    //     filter_by: "nama_lengkap",
    //   };
    //   // if (this.isGM) payload.jenis = 2;
    //   this.barangs = await this.$store.dispatch("barang/getData", payload);
    //   this.barangs.map((barang) => {
    //     barang.qty = 0;
    //     barang.diskon = 0;
    //     barang.harga_beli = 0;
    //     barang.isChangePrice = false;
    //     barang.harga_dasar_baru = 0; // bila ingin merubha harga dasar
    //   });
    // },
    async getSupplier() {
      const params = this.isGM ? { jenis: 2 } : {};
      const suppliers = await this.$store.dispatch("supplier/getData", params);
      suppliers.map((supplier) => {
        this.suppliers.push({
          text: supplier.instansi,
          value: supplier.id,
          alamat: supplier.alamat,
          nohp: supplier.nohp,
          email: supplier.email,
          nama: supplier.nama,
          // alamat: supplier.alamat,
        });
      });
    },
    async getId() {
      if (this.updateMode) {
        this.selectedBarangs = []
        const pembelian = await this.$store.dispatch("pembelian/getDataById", this.$route.params.id);
        if (!pembelian) {
          this.$router.push("/pembelian");
        }
        if (this.isGM && pembelian.jenis == 1) {
          this.$router.push("/pembelian");
        }
        this.form.tanggal = pembelian.tanggal;
        this.form.tanggal_kirim = pembelian.tanggal_kirim;
        this.form.tanggal_terima = pembelian.tanggal_terima;
        this.form.shipping_cost = pembelian.shipping_cost;
        this.form.ship_by = pembelian.ship_by;
        // this.form.vat_11 = pembelian.vat_11;
        this.form.jenis = pembelian.jenis;
        this.form.no = pembelian.no;
        this.form.kategori.value = pembelian.kategori;
        this.form.keterangan = pembelian.keterangan;
        this.form.id_supplier = pembelian.id_supplier;
        this.supplier = this.suppliers.find((sp) => sp.value == pembelian.id_supplier);
        let totalbeli = 0;
        pembelian.rincian.map((rincian) => {
          if (rincian.barang) {
            const { barang } = rincian;
            const item = {
              rincian_id: rincian.id,
              id: barang.id,
              nama: barang.nama,
              kode: barang.varian_item[0].kode,
              varian_item: [{
                varian: barang.varian_item[0].varian,
                no_batch: barang.varian_item[0].no_batch,
              }],
              kategori: {
                kategori: barang.kategori ? barang.kategori.kategori : null,
              },
              satuan: { satuan: barang.satuan ? barang.satuan.satuan : null, },
              qty: rincian.qty,
              diskon: rincian.diskon,
              harga_beli: rincian.harga_beli,
              total: this.formatRupiah(rincian.harga_beli * rincian.qty)
            };
            this.selectedBarangs.push(item);
            totalbeli += this.unFormatRupiah(item.total);
          }
        });
        this.form.vat_11 = (pembelian.vat_11 / totalbeli) * 100;
      }
    },
  },
  async created() {
    // this.getBarang();
    this.form.jenis = 2;
    await this.checkPermission();
    // if (!this.3) {
    //   this.$router.push("/pembelian");
    // }
    await this.getSupplier();

    this.form.tanggal = this.getCurrentDate();
    this.getId()

  },
};
</script>
