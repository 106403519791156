<template>
  <b-row>
    <b-col cols="12">
      <!-- <form-filter-export @onExport="doExport" :type_export="['excel']">
            <template v-if="hasPermissionPay || isOwner|| isCEO" #default>
                <b-form-group label="Jenis Pembelian">
                    <v-select :options="[{value: '', text: 'Semua'}, {value: 1, text: 'Rokok'}, {value: 2, text: 'Non-Rokok'}]" label="text" :reduce="option => option.value" v-model="selectedJenis"></v-select>
                </b-form-group>
            </template>
<template #dropdown-item="{form}">
                <b-dropdown v-if="hasPermissionPay || isOwner|| isCEO" variant="outline-primary" text="Export">
                    <b-dropdown-item href="#" @click.prevent="doExport(form, 'pembelian')">Pembelian</b-dropdown-item>
                    <b-dropdown-item href="#" @click.prevent="doExport(form, 'pembayaran')">Pembayaran</b-dropdown-item>
                </b-dropdown>
                <b-dropdown v-else-if="isGM" variant="outline-primary" text="Export">
                    <b-dropdown-item href="#" @click.prevent="doExport(form, 'pembelian')">Pembelian</b-dropdown-item>
                </b-dropdown>
            </template>
</form-filter-export> -->
      <b-card>
        <b-row>
          <!-- Header -->
          <b-col md="12">
            <!-- <b-button variant="primary" v-if="allowCreate()" @click.prevent="add"><feather-icon
                                icon="PlusIcon" class="mr-50" />
                            {{ $t('Add') }}</b-button>
                        <b-button variant="primary" v-if="allowCreate()" @click.prevent="in_transit_page"><feather-icon
                                icon="PlusIcon" class="mr-50" />
                            {{ $t('In Transit Stock') }}</b-button> -->
          </b-col>
          <!-- </b-row>
        <b-row > -->
          <b-col md="2" sm="4" class="my-1">
            <b-form-group
              class="mb-0"
              :label="$t('Per page')"
              label-size="sm"
              label-for="perPageSelect">
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions" />
            </b-form-group>
          </b-col>
          <b-col md="4" sm="8" class="my-1">
            <b-form-group
              :label="$t('Sort')"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0">
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75">
                  <template v-slot:first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6" class="my-1">
            <b-form-group
              :label="$t('Filter')"
              label-for="filterInput"
              class="mb-0"
              size="sm">
              <b-input-group>
                <b-form-input
                  v-model="filter"
                  placeholder="Tekan enter untuk cari"
                  size="sm"
                  @keydown.enter.prevent="getData()"></b-form-input>
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="getData()"
                    size="sm">
                    Cari
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- / -->
          <!-- </b-row>
        <b-row > -->
          <!-- <b-col md="4" sm="4" class="my-1">
                        <b-form-group class="mb-0" :label="$t('Supplier')" label-size="sm" label-for="supplierSelect">
                            <b-form-select id="supplierSelect" v-model="supplier" size="sm" :options="supplierOptions"
                                @change="getData()">
                                <b-form-select-option :value="null">- {{ $t('Semua') }} -</b-form-select-option>

                            </b-form-select>
                        </b-form-group>
                    </b-col> -->
        </b-row>
        <!-- Body -->
        <b-overlay :show="loading">
          <b-row>
            <template v-if="listtransit.length > 0">
              <b-col md="12" sm="12">
                <b-table
                  hover
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="fields"
                  :items="listtransit"
                  responsive>
                  <template #cell(order)="{ index }">
                    {{ 1 + index }}
                  </template>
                  <template #cell(kode)="{ item }">
                    {{ item.kode }}<br />
                    <small>SKU WMS : {{ item.no_batch }}</small
                    ><br />
                    <small>SKU Ginee : {{ item.main_sku }}</small>
                    <!-- <small>{{ item.nama }}</small> -->
                  </template>
                  <template #cell(nama)="{ item }">
                    {{ item.nama }}<br />
                    <small>{{ item.kategori.kategori }}</small> /
                    <small>{{ item.varian }}</small
                    ><br />
                    {{ item.kelas ? item.kelas.kelas : "-" }}
                  </template>
                  <template #cell(jenis)="{ item }">
                    {{ item.jenis == 1 ? "Rokok" : "Non-Rokok" }}
                  </template>
                  <template #cell(tanggal)="{ item }">
                    {{ humanDate(item.tanggal) }}
                  </template>
                  <template #cell(total)="{ item }">
                    <span v-if="item.informasi">
                      Rp {{ formatRupiah(item.informasi.total_pembelian) }}
                    </span>
                    <i class="text-danger" v-else> Tidak ada informasi </i>
                  </template>
                  <!-- <template #cell(status)="{ item }">
                                        <b-badge v-if="item.informasi && item.informasi.hutang == 0"
                                            variant="light-success">
                                            Lunas
                                        </b-badge>
                                        <b-badge v-else variant="light-danger">Belum Lunas</b-badge>
                                    </template> -->
                  <template #cell(status)="{ item }">
                    <template v-if="item.penerimaan_id != null">
                      <b-badge variant="success"> Diterima </b-badge>
                      <br />
                      <template v-if="item.selesai == 0">
                        <b-badge variant="warning">
                          {{ $t("Checking Process") }}
                        </b-badge>
                      </template>
                      <template v-if="item.selesai == 1">
                        <b-badge variant="success">
                          {{ $t("Checking Finish") }}
                        </b-badge>
                      </template>
                    </template>
                    <template v-else>
                      <b-badge variant="danger">Belum Diterima</b-badge>
                    </template>
                  </template>
                  <template #cell(actions)="{ item }">
                    <ul v-for="item in item.transit_stocks">
                      <li>
                        <b-row>
                          <b-col>{{ item.pembelian_data.no_pembelian }} - {{ item.pembelian_data.qty }}</b-col>
                          <b-col><b-button @click="move(item)" size="sm" class="mr-1 ml-1" variant="success">
                            <feather-icon icon="LogInIcon" /> Pindahkan pembelian produk ini ke Stok Jual </b-button></b-col>
                        </b-row>
                      </li>
                    </ul>
                  </template>
                  <template #cell(actionsall)="{ item }">
                        <b-button @click="moveit(item)" size="sm" class="mr-1 ml-1" variant="success">
                            <feather-icon icon="LogInIcon" /> Pindahkan seluruh produk ini ke Stok Jual </b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" sm="12">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="center"
                  size="sm"
                  class="my-0" />
              </b-col>
            </template>
            <template v-else>
              <b-col md="12" sm="12">
                <div class="alert alert-danger p-2">
                  <strong>{{ $t("Barang tidak ada") }}</strong>
                </div>
              </b-col>
            </template>
          </b-row>
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
  <!-- / -->
</template>
<script>
import {
  BOverlay,
  BDropdown,
  BDropdownItem,
  BFormSelectOption,
  BRow,
  BBadge,
  BCol,
  BCard,
  BPagination,
  BTable,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BFormInput,
  BFormSelect,
} from "bootstrap-vue";
import service from "@/services";
import FormFilterExport from "@core/components/form-filter-export/FormFilterExport.vue";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
    FormFilterExport,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BFormSelectOption,
    BRow,
    BCol,
    BBadge,
    BFormGroup,
    BCard,
    BPagination,
    BTable,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormInput,
    BFormSelect,
  },
  data: () => ({
    supplier: null,
    supplierOptions: [],
    selectedJenis: 2,
    modulePembelian: "Pembelian",
    loading: false,
    pageOptions: [10, 20, 50, 100],
    sortBy: "desc",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    perPage: 10,
    totalRows: 1,
    currentPage: 1,
    fields: [
      { key: "order", label: "No" },
      { key: "kode", label: "Barcode" },
      { key: "nama", label: "Nama Produk", sortable: true },
      // { key: "kelas", label: "Kelas", sortable: true },
      // { key: "varian", label: "Varian" },
      // { key: "harga_jual", label: "Harga Jual", sortable: true },
      // { key: "id_satuan", label: "Satuan" },
      // { key: 'no', label: 'No Invoice' },
      // { key: 'tanggal', label: 'PO Date', sortable: true },
      // { key: 'tanggal_kirim', label: 'Req Date', sortable: true },
      // { key: 'tanggal_terima', label: 'ETA', sortable: true },
      // { key: 'nama', label: 'Supplier' },
      //   {key: 'total', label: 'Total Pembelian'},
      // { key: 'status', label: 'Status' },
      { key: "actions", label: "Transit Stocks" },
      { key: "actionsall", label: "Action" },
    ],
    listtransit: [],
    jenis: null,
    menu: null,
    myDataOnly: false,
    hasPermissionPay: false,
    in_transit: null,
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    async getDataSupplier() {
      this.$store
        .dispatch("supplier/getData")
        .then((response) => {
          let items = JSON.parse(
            JSON.stringify(this.$store.state.supplier.datas)
          );
          // let items_total = this.$store.state.supplier.totals;
          this.supplierOptions = items;
          this.supplierOptions.map((hem) => {
            hem.text = hem.nama;
            hem.value = hem.id;
          });
          // this.totalRows = items_total
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getdbintransit() {
      let payload = {
        category_stock: "transit_stock",
        start: 0,
        length: 10,
      };
      this.$store
        .dispatch("pembelian/getInstock", payload)
        .then((response) => {
          // let items = JSON.parse(JSON.stringify(this.$store.state.supplier.datas));
          // let items_total = this.$store.state.supplier.totals;
          // this.in_transit = response
          this.in_transit = response.map((hem) => {
            hem.text = hem.nama;
            hem.value = hem.id;
          });
          // this.totalRows = items_total
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async doExport({ tgl_awal, tgl_akhir }, type_export = "pembayaran") {
      if (!tgl_awal || !tgl_akhir) {
        this.displayError({
          message: "Harap isi tanggal awal dan akhir!",
        });
        return false;
      }

      if (this.selectedJenis == null) {
        this.displayError({
          message: "Harap pilih jenis pembayaran",
        });
        return false;
      }

      try {
        const url =
          type_export == "pembayaran"
            ? `/excel-rekap-pembayaran-beli`
            : `excel-rekap-pembelian`;
        const config = {
          method: "get",
          url,
          params: {
            jenis: this.selectedJenis,
            tgl_awal,
            tgl_akhir,
          },
          headers: {
            Authorization: `bearer ${localStorage.token}`,
          },
        };

        this.loading = true;
        const response = await service.sendRequest(config);
        this.loading = false;
        await window.open(response.data);
        setTimeout(async () => {
          // delete selected file
          const arrFile = response.data.split("/");
          const filename = arrFile[arrFile.length - 1];

          await this.clearExportedFile(filename);
        }, 1000);
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    async checkPermissionPay() {
      const menu = await this.currentMenu(this.$route.path);
      const params = {
        level_id: this.user.level.id,
      };
      if (menu) {
        params.menu_id = menu.id;
      }
      const listStatus = await this.$store.dispatch(
        "statusrole/getData",
        params
      );

      // data_status => 1 approve gm, data_status => 2 pay finance
      const hasPermissionPay = listStatus.some(
        (status) => status.data_status == 2
      );
      this.hasPermissionPay = hasPermissionPay;
    },
    edit(item) {
      this.$router.push(`/pembelian/edit/${item.id}`);
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data pembelian ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
      }).then(async (res) => {
        if (res.value) {
          const payload = {
            fungsi: 1,
            id: item.id,
          };

          try {
            this.loading = true;
            await this.$store.dispatch("pembelian/save", [payload]);
            this.loading = false;
            this.displaySuccess({
              message: "Data pembelian berhasil dihapus",
            });
            this.getData();
          } catch (e) {
            this.loading = false;
            this.displayError(e);
            return false;
          }
        }
      });
    },
    move(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Stok produk in transit dari pembelian ini akan dipindahkan ke gudang`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
      }).then(async (res) => {
        if (res.value) {
          const payload = {
            penyimpanan_id: item.id,
            qty: item.pembelian_data.qty
          };
          try {
            this.loading = true;
            await this.$store.dispatch("pembelian/movetransit", [payload]);
            this.loading = false;
            this.displaySuccess({
              message: "Stok produk in transit dari pembelian berhasil dipindahkan ke gudang",
            });
            this.getData();
          } catch (e) {
            this.loading = false;
            this.displayError(e);
            return false;
          }
        }
      });
    },
    moveit(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Seluruh pembelian stok produk in transit ini akan dipindahkan ke gudang`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
      }).then(async (res) => {
        if (res.value) {
        //   const payload = {
        //     penyimpanan_id: item.id,
        //     qty: item.pembelian_data.qty
        //   };
          const payloads = item.transit_stocks.map(stock => ({
  penyimpanan_id: stock.id,
  qty: stock.pembelian_data.qty
}));
          try {
            this.loading = true;
            await this.$store.dispatch("pembelian/movetransit", payloads);
            this.loading = false;
            this.displaySuccess({
              message: "Seluruh pembelian stok produk in transit ini berhasil dipindahkan ke gudang",
            });
            this.getData();
          } catch (e) {
            this.loading = false;
            this.displayError(e);
            return false;
          }
        }
      });
    },
    purcon(item) {
      this.$swal({
        title: `Purchase Confirmation`,
        text: `Data pembelian ini sudah diterima?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
      }).then(async (res) => {
        if (res.value) {
          const payload = {
            // fungsi: 1,
            pembelian_id: item.id,
            gudang_id: this.myGudang.id
              ? this.myGudang.id
              : this.myGudang.data.id,
          };

          try {
            this.loading = true;
            const cek = await this.$store.dispatch(
              "pembelian/confirm",
              payload
            );
            this.loading = false;
            this.displaySuccess({
              message: "Data pembelian berhasil dikonfirmasi terima",
            });
            this.$router.push(
              `/penerimaanbarang/detail/${cek.data_penerimaan.id}`
            );
            this.getData();
          } catch (e) {
            this.loading = false;
            this.displayError(e);
            return false;
          }
        }
      });
    },
    add() {
      this.$router.push(`/pembelian/add`);
    },
    in_transit_page() {
      this.$router.push(`/penerimaanbarang/in-transit-stock`);
    },
    async getData() {
      this.loading = true;
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const params = {
        category_stock: "transit_stock",
        search: this.filter != null ? this.filter : null,
        order: this.sortBy,
        start: currentPage,
        length: this.perPage,
        // filter_by: "nama_lengkap",
        // supplier_id: this.supplier,
        // id_supplier: this.supplier,
      };
      this.$store
        .dispatch("pembelian/getInstock", params)
        .then((response) => {
          // let items = JSON.parse(JSON.stringify(this.$store.state.pembelian.datas));
          let items = response.data;
          let items_total = response.data.recordsTotal;
          this.listtransit = items;
          this.totalRows = items_total;
          this.loading = false;
        })
        .catch((e) => {
          this.displayError(e);
          this.loading = false;
          return false;
        });
    },
    detail(item) {
      this.$router.push(`/penerimaanbarang/checking/${item.penerimaan_id}`);
    },
    detail2(item) {
      this.$router.push(`/penerimaanbarang/detail/${item.penerimaan_id}`);
    },
    async getJenisData() {
      const params = {
        level_id: this.user.level.id,
      };
      if (this.menu) params.menu_id = this.menu.id;

      const status = await this.$store.dispatch("statusrole/getData", params);
      const findJenis1 = status.find((st) => st.data_status == 11);
      const findJenis2 = status.find((st) => st.data_status == 22);

      if (findJenis1) {
        this.jenis;
      } else if (findJenis2) {
        this.jenis = 2;
      } else if ((findJenis2 && findJenis1) || this.isOwner || this.isCEO) {
        this.jenis = null;
      } else {
        this.jenis = null;
      }
    },
    async getCurrentMenu() {
      this.menu = await this.currentMenu();
    },
    setField() {
      if (this.isHeadwh) {
        this.fields = [
          { key: "order", label: "No" },
          { key: "no", label: "No. Pembelian" },
          { key: "tanggal", label: "Tanggal" },
          { key: "total", label: "Total Pembelian" },
          // {key: 'status', label: 'Pembayaran'},
          { key: "actions", label: "#" },
        ];
      } else if (this.isSPV || this.isCEO) {
        this.fields = [
          { key: "order", label: "No" },
          { key: "no", label: "No. Pembelian" },
          { key: "jenis", label: "Jenis Pembelian Barang" },
          { key: "tanggal", label: "Tanggal" },
          { key: "total", label: "Total Pembelian" },
          // {key: 'status', label: 'Pembayaran'},
          { key: "actions", label: "#" },
        ];
      }
    },
  },
  watch: {
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getData();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getData();
      }
    },
  },
  async mounted() {
    // this.checkPermissionPay()
    // await this.getCurrentMenu()
    // await this.getJenisData()
    // await this.setField()
    await this.getData();
    // this.getDataSupplier()
    // this.getdbintransit()
  },
};
</script>
