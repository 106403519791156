var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('Item Details ')) + " "), _vm.selectedBarangs.length > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.selectedBarangs.length) + ")")]) : _vm._e()]), _c('b-button-group', [_c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.chooseBarang($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Choose Items")))])], 1), _c('import-barang', {
    on: {
      "getId": _vm.getId
    }
  }), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "3"
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Tanggal")]), _c('b-form-input', {
    staticClass: "w-full",
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tanggal,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tanggal", $$v);
      },
      expression: "form.tanggal"
    }
  })], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "lg": "9"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-for": "filter-input",
      "label-size": "sm"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "id": "filter-input",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Clear')))])], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', [_vm.selectedBarangs.length > 0 ? _c('b-table', {
    attrs: {
      "responsive": "",
      "small": "",
      "fields": _vm.fields,
      "items": _vm.selectedBarangs,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    scopedSlots: _vm._u([{
      key: "table-colgroup",
      fn: function (_ref) {
        var fields = _ref.fields;
        return _vm._l(fields, function (field) {
          return _c('col', {
            key: field.key,
            style: {
              width: field.key == 'no' ? '20px' : ''
            }
          });
        });
      }
    }, {
      key: "cell(no)",
      fn: function (_ref2) {
        var index = _ref2.index;
        return [_vm._v(" " + _vm._s(1 + index) + " ")];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.barang.kode ? item.barang.kode : '-')), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.varian_item[0].no_batch))])];
      }
    }, {
      key: "cell(kategori)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.barang.kategori ? item.barang.kategori.kategori : '-') + " ")];
      }
    }, {
      key: "cell(no_container)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Ex: MRT123213"
          },
          model: {
            value: item.no_container,
            callback: function ($$v) {
              _vm.$set(item, "no_container", $$v);
            },
            expression: "item.no_container"
          }
        })];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [item ? _c('span', [_vm._v(" " + _vm._s(item.barang.varian_item[0].varian)), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.kategori.kategori))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.barang.nama))])]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Barang tidak ada.")])];
      }
    }, {
      key: "cell(no_seal)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Ex: GR123"
          },
          model: {
            value: item.no_seal,
            callback: function ($$v) {
              _vm.$set(item, "no_seal", $$v);
            },
            expression: "item.no_seal"
          }
        })];
      }
    }, {
      key: "cell(harga_beli)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatRupiah(item.harga_beli)))]), item.diskon && item.diskon > 0 ? _c('strong', {
          staticClass: "text-danger"
        }, [_vm._v(" diskon " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(diskon)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")];
      }
    }, {
      key: "cell(qty)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('b-form-input', {
          attrs: {
            "size": "sm",
            "type": "number"
          },
          model: {
            value: item.qty,
            callback: function ($$v) {
              _vm.$set(item, "qty", $$v);
            },
            expression: "item.qty"
          }
        }), _vm._v(" /" + _vm._s(item.barang.satuan ? item.barang.satuan.satuan : "") + " ")];
      }
    }, {
      key: "cell(total)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty))) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref12) {
        var item = _ref12.item,
          index = _ref12.index;
        return [_c('feather-icon', {
          staticClass: "text-danger cursor-pointer",
          attrs: {
            "icon": "MinusCircleIcon",
            "size": "24"
          },
          on: {
            "click": function ($event) {
              return _vm.removeBarang(item, index);
            }
          }
        })];
      }
    }, {
      key: "cell(keterangan)",
      fn: function (_ref13) {
        var item = _ref13.item,
          index = _ref13.index;
        return [_c('b-form-input', {
          model: {
            value: item.keterangan,
            callback: function ($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        })];
      }
    }, {
      key: "cell(type)",
      fn: function (_ref14) {
        var item = _ref14.item,
          index = _ref14.index;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.opttype
          },
          model: {
            value: item.type,
            callback: function ($$v) {
              _vm.$set(item, "type", $$v);
            },
            expression: "item.type"
          }
        })];
      }
    }], null, false, 77312368)
  }) : _c('div', {
    staticClass: "alert alert-danger p-2"
  }, [_vm._v(_vm._s(_vm.$t("Please select item")))]), _c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-3 px-2"
  }, [_c('h4', [_vm._v("Total Qty:")]), _c('h2', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.formatRupiah(_vm.totalPembelian)))])])], 1)], 1)], 1)], 1), _c('b-col', [_c('barang-modal', {
    on: {
      "submit": _vm.onChooseBarang
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "disabled": !_vm.isValidForm,
      "block": "",
      "variant": !_vm.isValidForm ? 'secondary' : 'primary'
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Simpan")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }