<template>
  <b-overlay :show="loading">
    <!-- <form-filter-export @onExport="exportpengirimanBarang"></form-filter-export> -->
    <b-card>
      <!-- <b-row v-if="allowCreate()">
        <b-col class="my-1">
          <b-button
            v-b-modal.modal-tambah
            variant="primary"
            v-if="allowCreate() && myGudang"
            @click="add()"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            Penyimpanan
          </b-button>
          <b-button v-if="allowCreate()" variant="primary" class="ml-1"
                @click.prevent="$router.push({ name: 'route-diskon' })">
                <feather-icon icon="SettingsIcon" class="mr-50" />Atur Voucher Diskon </b-button>
        </b-col>
      </b-row> -->
      <b-row>
        <!-- <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0" label-size="sm" :label="$t('Warehouse')" label-for="warehouse">
            <b-form-select :options="blok_id" v-model="cariblok" :reduce="(option) => option.value" label="text"
              @change="getpenyimpananBarangs" />
          </b-form-group>
        </b-col> -->
        <b-col>
          <b-button
            :disabled="selectedginee.length == 0"
            variant="warning"
            @click="cekstokginee()"
            class="mr-1"
          >
            Cek Stok Ginee ({{
              selectedginee.length ? selectedginee.length : 0
            }})
          </b-button>
          <!-- </b-col>
        <b-col> -->
          <b-button
            :disabled="selectedginee.length == 0"
            variant="warning"
            @click="openModalmin()"
            class="mr-1"
          >
            Change Minimum Stock ({{
              selectedginee.length ? selectedginee.length : 0
            }})
          </b-button>
          <b-modal
            id="min-modal"
            title="Change Minimum Stock"
            ref="minmodal"
            :hide-footer="true"
            size="md"
            centered
          >
            <b-form-input type="number" v-model="minimum_stok" placeholder="0">
            </b-form-input>
            <br />
            <b-button @click="ubahminstok()" variant="primary">{{
              $t("Save")
            }}</b-button>
          </b-modal>
          <!-- </b-col>
        <b-col> -->
          <b-button
            :disabled="selectedginee.length == 0"
            variant="success"
            @click="updatestokginee()"
            class="mr-1"
          >
            Update Stok WMS ke Ginee ({{
              selectedginee.length ? selectedginee.length : 0
            }})
          </b-button>
        </b-col>
      </b-row>
      <hr />
      <b-row class="mt-1">
        <b-col md="1" sm="1"> Category </b-col>
        <b-col md="3" sm="3">
          <b-form-group>
            <b-form-select
              v-model="kategori_id"
              :options="itemsKategori"
              @change="getpenyimpananBarangs()"
            >
              <template #first>
                <b-form-select-option :value="null"
                  >-- {{ $t("All") }} --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="1" sm="1"> Sort </b-col>
        <b-col md="2" sm="2">
          <b-form-group>
            <b-form-select
              v-model="sortBarang"
              :options="[
                { value: 'asc', text: 'Ascending' },
                { value: 'desc', text: 'Descending' },
              ]"
              @change="getpenyimpananBarangs()"
            >
              <template #first>
                <b-form-select-option :value="null"
                  >-- {{ $t("None") }} --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="1" sm="1" class=""> Lainnya </b-col>
        <b-col md="4" sm="4" class="">
          <b-form-group>
            <b-input-group>
              <!-- b-form-select sebagai bagian dari input group -->
              <b-input-group-prepend>
                <b-form-select
                  v-model="searchOptions"
                  :options="[
                    { value: 'kelas_id', text: 'Kelas' },
                    { value: 'main_sku', text: 'SKU Ginee' },
                    { value: 'kode', text: 'Barcode' },
                  ]"
                  class="custom-select"
                ></b-form-select>
              </b-input-group-prepend>

              <!-- b-form-input sebagai bagian dari input group -->
              <b-form-input
                v-if="searchOptions !== 'kelas_id'"
                @keydown.enter.prevent="getpenyimpananBarangs()"
                v-model="customefilter"
                placeholder="Masukkan teks"
              ></b-form-input>

              <b-form-select
                v-if="searchOptions == 'kelas_id'"
                v-model="kelas_id"
                :options="itemsKelas"
                @change="getpenyimpananBarangs()"
              >
                <template #first>
                  <b-form-select-option :value="null"
                    >-- {{ $t("All") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="1" sm="1"> Pre Order </b-col>
        <b-col md="3" sm="3">
          <b-form-group>
            <b-form-select
              v-model="is_pre_order"
              :options="[
                { value: 1, text: 'Ya' },
                { value: 0, text: 'Tidak' },
              ]"
              @change="getpenyimpananBarangs()"
            >
              <template #first>
                <b-form-select-option :value="null"
                  >-- {{ $t("All") }} --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group
            class="mb-0"
            label-size="sm"
            :label="$t('Per page')"
            label-for="perPage"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="10" sm="8" class="my-1">
          <b-form-group
            :label="$t('Filter')"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Tekan enter untuk cari"
                @keydown.enter.prevent="getpenyimpananBarangs()"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="
                    filter = '';
                    getpenyimpananBarangs();
                  "
                >
                  Clear
                </b-button>
              </b-input-group-append>
              <!-- <b-input-group-append>
                <b-button :disabled="selectedginee.length == 0" variant="warning" @click="cekstokginee();">
                  Cek Stok Ginee ({{ selectedginee.length ? selectedginee.length : 0 }})
                </b-button>
              </b-input-group-append>
              <b-input-group-append>
                <b-button :disabled="selectedginee.length == 0" variant="warning" @click="openModalmin();">
                  Change Minimum Stock ({{ selectedginee.length ? selectedginee.length : 0 }})
                </b-button>
                <b-modal id="min-modal" title="Change Minimum Stock" ref="minmodal" :hide-footer="true" size="md"
                  centered>
                  <b-form-input type="number" v-model="minimum_stok" placeholder="0">
                  </b-form-input>
                  <br>
                  <b-button @click="ubahminstok()" variant="primary">{{ $t('Save') }}</b-button>
                </b-modal>
              </b-input-group-append>
              <b-input-group-append>
                <b-button :disabled="selectedginee.length == 0" variant="success" @click="updatestokginee();">
                  Update Stok WMS ke Ginee ({{ selectedginee.length ? selectedginee.length : 0 }})
                </b-button>
              </b-input-group-append> -->
              <!-- <b-input-group-append>
                <b-button variant="primary" @click="cekstokginee();">
                  Update Stok WMS  Ginee ({{ selectedginee.length ? selectedginee.length : 0 }})
                </b-button>
              </b-input-group-append> -->
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-overlay :show="loadingtable">
            <b-table
              v-if="penyimpananBarangs.length > 0"
              small
              hover
              responsive
              :per-page="perPage"
              :items="penyimpananBarangs"
              :fields="fieldsasli"
            >
              <!-- <template #thead-top="data">
              <b-tr>
                <b-th colspan="4" style="background-color: transparent"></b-th>
                <b-th variant="secondary">Type 1</b-th>
                <b-th colspan="4" style="" class="text-center"
                  >Lokasi Penyimpanan</b-th
                >
                <b-th ></b-th>
              </b-tr>
            </template> -->
              <template #head(checkbox)>
                <b-form-checkbox v-model="allChecked"></b-form-checkbox>
              </template>
              <template #cell(checkbox)="{ item }">
                <b-form-checkbox
                  v-model="selectedginee"
                  :value="item"
                ></b-form-checkbox>
              </template>
              <template #cell(no)="row">
                <strong class="text-center">
                  {{ row.index + 1 }}
                </strong>
              </template>
              <template #cell(kode)="{ item }">
                {{ item ? item.kode : "-" }}<br />
                    <small>SKU WMS : {{ item.no_batch }}</small
                    ><br />
                    <small>SKU Ginee : {{ item.main_sku }}</small>
                <!-- <small>{{ item ? item.no_batch : "-" }}</small> -->
              </template>
              <template #cell(fotopro)="{ item }">
                <template
                  v-if="item.photo && item.photo.length > 0"
                  class="image-gallery"
                >
                  <!-- <img v-for="(image, index) in item.photo" :key="index" :src="image.path" :alt="image.path"
                  class="viewer-image" ref="imageElement" /> -->
                  <img
                    style="width: 50px"
                    @click="openModal(item.photo[0].path, item.barcode)"
                    :src="item.photo[0].path"
                    alt="Image description"
                  />
                </template>
                <small v-else class="text-danger"
                  >Photo product item not yet created</small
                >
                <!-- <feather-icon v-else icon="Icon" size="32" /> -->
                <br />
                <img
                  v-if="item.barcode"
                  @click="
                    openModal(
                      item.photo.length > 0 ? item.photo[0].path : null,
                      item.barcode
                    )
                  "
                  style="width: 75px"
                  :src="item.barcode"
                  alt="Foto"
                />
                <small v-else class="text-danger"
                  >Barcode not yet created</small
                >
                <!-- <feather-icon v-else icon="Icon" size="32" /> -->
                <b-modal
                  id="image-modal"
                  title="Preview Photo Product Item and Barcode"
                  ref="imageModal"
                  :hide-footer="true"
                  size="lg"
                  centered
                >
                  <div ref="imageViewer">
                    <img
                      v-if="currentImage"
                      :src="currentImage"
                      class="img-fluid"
                      alt="Full-size"
                    />
                    <small v-else class="text-danger"
                      >Photo product item not yet created</small
                    >
                    <br />
                    <img
                      v-if="currentBarcode"
                      :src="currentBarcode"
                      class="img-fluid"
                      alt="Full-size"
                    />
                    <small v-else class="text-danger"
                      >Barcode not yet created</small
                    >
                  </div>
                </b-modal>
              </template>
              <template #cell(nama)="{ item }">
                <!-- <div class="v-align-top"> -->
                {{ item ? item.nama : "-" }}<br />
                <small>{{ item.kategori.kategori }}</small> /
                <small>
                  {{ item ? item.varian : "-" }}
                </small>
                <br />
                {{ item.kelas ? item.kelas.kelas : "-" }}
                <!-- <br>
                <small>{{ $t('Room') }} : </small>
                <small>{{ item.blok ? item.blok.blok : "-" }}</small>
                <small>{{ item.palet ? item.palet.palet : "-" }}</small>
                <small>{{ item.rak ? item.rak.rak : "-" }}</small>
                <small>{{ item.laci ? item.laci.laci : "-" }}</small> -->
                <!-- </div> -->
              </template>
              <template #cell(stok_ginee)="{ item }">
                {{ item.stok_ginee ? formatRupiah(item.stok_ginee) : 0 }}
              </template>
              <template #cell(stocks)="{ item }">
                <!-- {{ item.stocks }} -->
                <b-card border-variant="secondary" class="mb-0">
                  <div class="text-center">
                    <template v-for="data in item.stocks">
                      <b-row>
                        <b-col>
                          <div class="text-right">{{ data.blok.blok }}</div>
                        </b-col>
                        <b-col md="1"> : </b-col>
                        <b-col>
                          <template v-if="data.jumlah > data.minimum_stok">
                            <div class="text-left">
                              <b class="text-success">{{
                                formatRupiah(data.jumlah)
                              }}</b>
                            </div>
                          </template>
                          <template v-if="data.jumlah <= data.minimum_stok">
                            <div class="text-left">
                              <b class="text-danger">{{
                                formatRupiah(data.jumlah)
                              }}</b>
                            </div>
                          </template>
                        </b-col>
                      </b-row>
                    </template>
                    <b-row>
                        <b-col>
                          <div class="text-right">Total</div>
                        </b-col>
                        <b-col md="1"> : </b-col>
                        <b-col>
                          <template v-if="item.total_stok > totalJumlah(item.stocks)">
                            <div class="text-left">
                              <b class="text-success">{{
                                formatRupiah(item.total_stok)
                              }}</b>
                            </div>
                          </template>
                          <template v-if="item.total_stok <= totalJumlah(item.stocks)">
                            <div class="text-left">
                              <b class="text-danger">{{
                                formatRupiah(item.total_stok)
                              }}</b>
                            </div>
                          </template>
                          <!-- <template v-if="item.total_stok > item.stocks[0] ? item.stocks[0].minimum_stok : 0">
                            <div class="text-left">
                              <b class="text-success">{{
                                formatRupiah(item.total_stok)
                              }}</b>
                            </div>
                          </template>
                          <template v-if="item.total_stok <= item.stocks[0] ? item.stocks[0].minimum_stok : 0">
                            <div class="text-left">
                              <b class="text-danger">{{
                                formatRupiah(item.total_stok)
                              }}</b>
                            </div>
                          </template> -->
                        </b-col>
                      </b-row>
                  </div>
                  <!-- <div class="text-center">
                    (Min. Stocks : {{ formatRupiah(totalJumlah(item.stocks)) }})<br>
                    <b-button v-if="item.stocks.length > 1" size="sm" block variant="warning" class="" @click="modaltransfer(item)">Stock Transfer</b-button>
                  </div> -->
                  <div class="text-center">
                    (Min. Stocks : {{ formatRupiah(item.stocks[0] ? item.stocks[0].minimum_stok : 0) }})<br>
                    <b-button v-if="item.stocks.length > 1" size="sm" block variant="warning" class="" @click="modaltransfer(item)">Stock Transfer</b-button>
                  </div>
                </b-card>
              </template>
              <template #cell(blok)="{ item }">
                {{ item.blok ? item.blok.blok : "-" }}
              </template>
              <template #cell(palet)="{ item }">
                {{ item.palet ? item.palet.palet : "-" }}
              </template>
              <template #cell(rak)="{ item }">
                {{ item.rak ? item.rak.rak : "-" }}
              </template>
              <template #cell(laci)="{ item }">
                {{ item.laci ? item.laci.laci : "-" }}
              </template>
              <template #cell(jumlah)="{ item }">
                <template v-if="item.jumlah > item.minimum_stok">
                  <b class="text-success">{{
                    item.jumlah ? item.jumlah : 0
                  }}</b>
                  / {{ formatRupiah(item.jumlah * item.harga_dasar) }}<br />
                  <small>
                    (Konversi Satuan :
                    {{ item.konversi_ket == null ? 0 : item.konversi_ket }})
                    <br />(Min. Stok = {{ item.minimum_stok }})
                  </small>
                </template>
                <template v-if="item.jumlah <= item.minimum_stok">
                  <b class="text-danger">{{ item.jumlah ? item.jumlah : 0 }}</b>
                  / {{ formatRupiah(item.jumlah * item.harga_dasar) }}<br />
                  <small>
                    (Konversi Satuan :
                    {{ item.konversi_ket == null ? 0 : item.konversi_ket }})
                    <br />(Min. Stok = {{ item.minimum_stok }})
                  </small>
                </template>
              </template>
              <template #cell(satuan)="{ item }">
                {{ item.konversi_ket }}
              </template>

              <template #cell(selesai)="data">
                <b-badge variant="primary">
                  {{
                    data.item.status_ket
                      ? data.item.status_ket.toUpperCase()
                      : "-"
                  }}
                </b-badge>
              </template>
              <template #cell(po)="{ item }">
                {{ item.po ? item.po.no : "po kosong" }}
              </template>

              <template #cell(tanggal_pengiriman)="{ item }">
                {{ humanDate(item.tanggal) }}
              </template>

              <template #cell(tanggal_invoice)="{ item }">
                {{ humanDate(item.tanggal_invoice) }}
              </template>

              <template #cell(publish)="{ item }">
                <section class="flex item-center">
                  <b-form-checkbox
                    v-model="item.is_published"
                    :value="true"
                    :unchecked-value="false"
                    @change="togglePublish(item)"
                    switch
                  >
                  </b-form-checkbox>
                </section>
              </template>
              <template #cell(actions)="{ item }">
                <section class="flex item-center">
                  <b-button
                    title="'Detail'"
                    size="sm"
                    @click="$router.push(`/owner/pengiriman/detail/${item.id}`)"
                    class="mr-1"
                    variant="outline-primary"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    v-if="
                      (allowUpdate() && item.status == 1) ||
                      (allowUpdate() && !isAdminGudang && !isSpvcrm)
                    "
                    title="'Batalkan Pengiriman'"
                    size="sm"
                    @click="batal(item)"
                    class="mr-1"
                    variant="outline-danger"
                  >
                    <feather-icon icon="XCircleIcon" />
                  </b-button>
                  <b-button
                    v-if="
                      (allowUpdate() && item.status == 0) ||
                      (allowUpdate() && !isAdminGudang && !isSpvcrm)
                    "
                    title="'Ubah'"
                    size="sm"
                    @click="$router.push(`/owner/pengiriman/edit/${item.id}`)"
                    class="mr-1"
                    variant="outline-info"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    v-if="
                      (allowDelete() && item.status == 0) ||
                      (allowUpdate() && !isAdminGudang && !isSpvcrm)
                    "
                    title="'Hapus'"
                    size="sm"
                    @click="remove(item)"
                    class="mr-1"
                    variant="outline-danger"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </section>
              </template>
              <template
                #cell(actionsCabang)="{ item }"
                v-if="!isSpvcrm && !isSPV"
              >
                <section class="flex item-center">
                  <b-button
                    title="'Ubah Barang'"
                    size="sm"
                    @click="ubahbrg(item)"
                    class="mr-1"
                    variant="primary"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                  <b-button
                    title="'Ubah Lokasi Penyimpanan'"
                    size="sm"
                    @click="ubahpeny(item)"
                    class=""
                    variant="info"
                  >
                    <feather-icon icon="Edit3Icon" />
                  </b-button>
                </section>
              </template>
            </b-table>
            <div v-else class="alert alert-danger p-2">
              <strong>Data</strong> yang dicari tidak tersedia
            </div>
          </b-overlay>
        </b-col>
        <b-col cols="12">
          <b-overlay :show="loadingtable">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
            id="transfer-modal"
            title="Stock Transfer"
            :hide-footer="true"
            size="lg"
            centered
            no-close-on-backdrop no-close-on-esc
          >
          <b-row>
    <b-col class="text-right" cols="4">
        Total Stock :
    </b-col>
    <b-col>
        <span style="display: block; text-align: left;">
            {{datatf ? datatf.total_stok : 0 }} {{datatf ? datatf.satuan ? datatf.satuan.satuan : "-" : "-" }}
        </span>
    </b-col>
    <b-col>
        <span style="display: block; text-align: left;">
            (Min. Stocks : {{ formatRupiah(totalJumlah(datatf ? datatf.stocks : [])) }})
        </span>
    </b-col>
</b-row>
<hr />

<b-row>
    <b-col class="text-right" cols="4">
      <h6>Gudang Asal</h6>
      <v-select
                      v-model="gudangasal"
                      :options="datatfgudang"
                      label="text"
                    />
                    <br>
                   {{$t('Stock : ')}}{{gudangasal ? gudangasal.jumlah : 0}}
    </b-col>
    <b-col class="text-center" cols="4">
      <h6>Stok yang akan ditransfer</h6>
      <b-form-input type="number" v-model="stocktf" placeholder="0">
      </b-form-input> 
      <small v-if="stocktf > (gudangasal ? gudangasal.jumlah : 0)" class="text-danger">Stok yang akan ditransfer melebihi stok gudang asal</small>
      <br>
      <b-button block @click="ubahstocktf()" variant="primary">{{
              $t("Save")
            }}</b-button>
    </b-col>
    <b-col class="text-left" cols="4">
      <h6>Gudang Tujuan</h6>
      <v-select
                      v-model="gudangtujuan"
                      :options="datatfgudang"
                      label="text"
                    />      <br>
                    {{$t('Stock')}} : {{gudangtujuan ? gudangtujuan.jumlah : 0}}
    </b-col>
    <!-- <b-col>

      <b-input-group>
        <b-input-group-prepend>
          <b-form-select
                      id="perPageSelect"
                      v-model="datatf"
                      :options="pageOptions"
                    />
        </b-input-group-prepend>
        <b-form-input placeholder="Dropdown on left" />
      </b-input-group>
    </b-col> -->

  </b-row>

            <!-- <b-form-input type="number" v-model="minimum_stok" placeholder="0">
            </b-form-input>
            <br />
            <b-button @click="ubahminstok()" variant="primary">{{
              $t("Save")
            }}</b-button> -->
          </b-modal>
    <b-modal
      v-if="allowCreate() || allowUpdate()"
      v-model="showModal"
      id="modal-"
      ok-title="Tutup"
      ok-variant="secondary"
      ok-only
      centered
      title="Form "
      size="xl"
    >
      <validation-observer ref="form">
        <div class="d-flex">
          <feather-icon icon="PackageIcon" size="19" />
          <h6 class="ml-50 mb-2">Penyimpanan Barang</h6>
        </div>
        <b-row>
          <!-- List barang di penerimaan -->
          <b-col sm="12" md="8" lg="8" xl="8">
            <b-card :title="$t("{{$t("Choose Items")}})">
              <b-row>
                <b-col sm="6" md="6" lg="6">
                  <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50"
                      >Per page</label
                    >
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      class="w-50"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="6" lg="6">
                  <b-form-group
                    :label="$t('Sort')"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-select
                        id="sortBySelect"
                        v-model="sortBy"
                        :options="sortOptions"
                        class="w-75"
                      >
                        <template v-slot:first>
                          <option value="">-- none --</option>
                        </template>
                      </b-form-select>
                      <b-form-select
                        v-model="sortDesc"
                        size="sm"
                        :disabled="!sortBy"
                        class="w-25"
                      >
                        <option :value="false">Asc</option>
                        <option :value="true">Desc</option>
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6" lg="12">
                  <b-form-group>
                    <label for="Cari Barang">Cari Barang</label>
                    <b-input-group>
                      <b-form-input
                        v-model="caribarang"
                        placeholder="Tekan enter untuk cari"
                        @keydown.enter.prevent="getDataBarangs()"
                      ></b-form-input>
                      <!-- <b-form-input placeholder="Button on right" /> -->
                      <b-input-group-append>
                        <b-button
                          variant="outline-primary"
                          @click="getDataBarangs()"
                        >
                          Cari
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="12" lg="12">
                  <b-table
                    v-if="loaded"
                    striped
                    small
                    hover
                    responsive
                    :per-page="perPageBarangs"
                    :items="dataBarangs"
                    :fields="fieldsBarangs"
                    :sort-by.sync="sortByBarangs"
                    :sort-desc.sync="sortDescBarangs"
                    :sort-direction="sortDirectionBarangs"
                    :filter-included-fields="filterOnBarangs"
                    @filtered="onFilteredBarangs"
                  >
                    <template #cell(penyimpanan)="data">
                      <!-- <b-form-checkbox @change="selectAll" v-model="selected" /> -->
                      <b-form-checkbox
                        :value="data.item"
                        v-model="pilihbarang"
                      />
                    </template>
                    <template #cell(no)="row">
                      <strong class="text-center">
                        {{ row.index + 1 }}
                      </strong>
                    </template>
                    <template #cell(kode)="{ item }">
                      {{ item ? item.kode : "-" }}
                    </template>
                    <template #cell(nama)="{ item }">
                      {{ item ? item.varian : "-" }}<br />
                      <small>
                        {{ item ? item.nama : "-" }}
                      </small>
                    </template>
                    <template #cell(blok)="{ item }">
                      {{ item.blok ? item.blok.blok : "-" }}
                    </template>
                    <template #cell(palet)="{ item }">
                      {{ item.palet ? item.palet.palet : "-" }}
                    </template>
                    <template #cell(rak)="{ item }">
                      {{ item.rak ? item.rak.rak : "-" }}
                    </template>
                    <template #cell(laci)="{ item }">
                      {{ item.laci ? item.laci.laci : "-" }}
                    </template>
                    <template #cell(jumlah)="{ item }">
                      {{ item.jumlah ? item.jumlah : "-" }}
                    </template>
                    <template #cell(satuan)="{ item }">
                      <!-- {{ item.satuan ? item.satuan.satuan : '-' }} -->
                      {{ item.konversi_ket }}
                    </template>
                  </b-table>
                </b-col>
                <b-col cols="12">
                  <b-pagination
                    v-model="currentPageBarangs"
                    :total-rows="totalRowsBarangs"
                    :per-page="perPageBarangs"
                    align="center"
                    size="sm"
                    class="my-0"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- Penempatan gudang -->
          <b-col sm="12" md="4" lg="4" xl="4">
            <b-card title="Tentukan Penyimpanan">
              <form>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="gudang"
                    rules="required"
                  >
                    <label for="">Pilih Gudang</label>
                    <b-form-select
                      :options="dataGudang"
                      v-model="form.gudang_id"
                      :required="!form.gudang_id"
                      :reduce="(option) => option.value"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="blok"
                    rules="required"
                  >
                    <label for="">Pilih Blok</label>
                    <b-form-select
                      :options="blok_id"
                      v-model="form.blok_id"
                      :required="!form.blok_id"
                      :reduce="(option) => option.value"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <label for="">Pilih Pallet</label>
                  <!-- <b-form-select :options="palet_id" v-model="form.palet_id" :required="!form.palet_id"
                    :reduce="option => option.value" label="text" />-->
                </b-form-group>
                <b-form-group>
                  <label for="">Pilih Rak (optional)</label>
                  <b-form-select
                    v-model="form.rak_id"
                    :options="rak_id"
                    :reduce="(option) => option.value"
                    label="text"
                    class="mb-2"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="">Pilih Laci (optional)</label>
                  <b-form-select
                    v-model="form.laci_id"
                    :options="laci_id"
                    :reduce="(option) => option.value"
                    label="text"
                    class="mb-2"
                  />
                </b-form-group>
                <b-form-group>
                  <b-button @click.prevent="addBarang" variant="primary"
                    >Add</b-button
                  >
                </b-form-group>
              </form>
            </b-card>
          </b-col>
          <!-- Barang yang sudah disimpan -->
          <b-col cols="12">
            <b-card>
              <b-table
                :fields="fieldsAddedItems"
                bordered
                striped
                responsive
                :items="addedItems"
              >
                <template #cell(no)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(lokasi)="{ item }">
                  <strong>
                    {{ item.lokasi }}
                  </strong>
                </template>
                <template #cell(action)="row">
                  <b-link
                    class="text-danger"
                    @click.prevent="addedItems.splice(row.index, 1)"
                  >
                    <feather-icon size="24" icon="XCircleIcon"></feather-icon>
                  </b-link>
                </template>
              </b-table>
              <div class="d-flex justify-content-end">
                <b-button variant="primary" @click.prevent="submit">
                  Simpan
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
    <b-modal
      v-if="allowCreate() || allowUpdate()"
      v-model="showModalBrg"
      ok-title="Tutup"
      ok-variant="secondary"
      ok-only
      centered
      :title="'Ubah Barang Penyimpanan'"
      size="lg"
    >
      <b-col sm="12" md="12" lg="12">
        {{ this.ubah ? (this.ubah.barang ? this.ubah.barang.nama : 0) : 0 }}
        <b-card
          border-variant="primary"
          title="Barang"
          bg-variant="transparent"
          class="shadow-none"
        >
          <b-row>
            <b-col sm="12" md="12" lg="12">
              <b-form-group>
                <label for="Cari Barang">Cari Barang</label>
                <b-input-group>
                  <b-form-input
                    v-model="caribarang"
                    placeholder="Tekan enter untuk cari"
                    @keydown.enter.prevent="getDataBarangs()"
                  ></b-form-input>
                  <!-- <b-form-input placeholder="Button on right" /> -->
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="getDataBarangs()"
                    >
                      Cari
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="12">
              <b-table
                v-if="loaded"
                striped
                small
                hover
                responsive
                :per-page="perPageBarangs"
                :items="dataBarangs"
                :fields="fieldsBarangs"
                :sort-by.sync="sortByBarangs"
                :sort-desc.sync="sortDescBarangs"
                :sort-direction="sortDirectionBarangs"
                :filter-included-fields="filterOnBarangs"
                @filtered="onFilteredBarangs"
              >
                <template #cell(penyimpanan)="data">
                  <!-- <b-form-checkbox @change="selectAll" v-model="selected" /> -->
                  <b-form-checkbox :value="data.item" v-model="pilihbarang2" />
                </template>
                <template #cell(no)="row">
                  <strong class="text-center">
                    {{ row.index + 1 }}
                  </strong>
                </template>
                <template #cell(kode)="{ item }">
                  {{ item.kode ? item.kode : "-" }}
                </template>
                <template #cell(harga_dasar)="{ item }">
                  {{ item.harga_dasar ? formatRupiah(item.harga_dasar) : "-" }}
                </template>
                <template #cell(satuan)="{ item }">
                  {{ item.satuan ? item.satuan.satuan : "-" }}
                </template>
              </b-table>
              <div class="d-flex justify-content-end">
                <b-button variant="primary" @click.prevent="submitbrg">
                  Ubah Barang Penyimpanan
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-modal>
    <b-modal
      v-if="allowCreate() || allowUpdate()"
      v-model="showModalPen"
      ok-title="Tutup"
      ok-variant="secondary"
      ok-only
      centered
      :title="'Ubah Lokasi Penyimpanan'"
      size="lg"
    >
      <b-col sm="12" md="12" lg="12">
        {{
          this.ubahpen
            ? this.ubahpen.barang
              ? this.ubahpen.barang.nama
              : 0
            : 0
        }}
        <!-- <b-col sm="6" md="6" lg="6" xl="6"> -->
        <b-card title="Buat Penyimpanan Baru" border-variant="primary">
          <validation-observer ref="form2">
            <form>
              <b-row>
                <b-col sm="4" md="4" lg="4" xl="4">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="blok"
                      rules="required"
                    >
                      <label for="">Pilih Blok</label>
                      <b-form-select
                        :options="blok_id"
                        v-model="ubahpen.blok_id"
                        :required="!ubahpen.blok_id"
                        :reduce="(option) => option.value"
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="4" md="4" lg="4" xl="4">
                  <b-form-group>
                    <label for="">Pilih Palet</label>
                    <b-form-select
                      :options="palet_id"
                      v-model="ubahpen.palet_id"
                      :reduce="(option) => option.value"
                      label="text"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="4" md="4" lg="4" xl="4">
                  <b-form-group>
                    <label for="">Pilih Rak (optional)</label>
                    <b-form-select
                      v-model="ubahpen.rak_id"
                      :options="rak_id"
                      :reduce="(option) => option.value"
                      label="text"
                      class="mb-2"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="4" md="4" lg="4" xl="4">
                  <b-form-group>
                    <label for="">Pilih Laci (optional)</label>
                    <b-form-select
                      v-model="ubahpen.laci_id"
                      :options="laci_id"
                      :reduce="(option) => option.value"
                      label="text"
                      class="mb-2"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </form>
          </validation-observer>
        </b-card>
        <div class="d-flex justify-content-end">
          <b-button variant="primary" @click.prevent="submitpen">
            Ubah Lokasi Penyimpanan
          </b-button>
        </div>
      </b-col>
      <!-- </b-col> -->
    </b-modal>
  </b-overlay>
</template>
<script>
import vSelect from "vue-select";
import { extend } from "vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormFilterExport from "@core/components/form-filter-export/FormFilterExport.vue";
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BLink,
  BOverlay,
  BModal,
  BTable,
  VBTooltip,
  BTooltip,
  BAvatar,
  BCard,
  BBadge,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormSelectOption,
  BInputGroupPrepend,
} from "bootstrap-vue";
// import mixin from './mixin'
export default {
  // mixins: [mixin],
  components: {
    vSelect,
    BTableSimple,
    BFormSelectOption,
    BInputGroupPrepend,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BLink,
    BForm,
    FormFilterExport,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    ValidationObserver,
    ValidationProvider,
    BCard,
    VBTooltip,
    BTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BOverlay,
    BAvatar,
    BFormSelect,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data: () => ({
    datatfgudang: [],
    gudangasal: null,
stocktf: 0,
gudangtujuan: null,
    datatf: null,
    is_pre_order: null,
    minimum_stok: 0,
    cariblok: null,
    dataBarangs: null,
    caribarang: null,
    pageOptionsBarangs: [10, 20, 50],
    sortByBarangs: null,
    sortDescBarangs: false,
    sortDirectionBarangs: "asc",
    filterBarangs: null,
    filterOnBarangs: [],
    perPageBarangs: 10,
    totalRowsBarangs: 1,
    currentPageBarangs: 1,
    loaded: true,
    showModal: false,
    showModalBrg: false,
    pilihbarang2: null,
    ubah: null,
    ubahpen: {
      blok_id: null,
      palet_id: null,
      rak_id: null,
      laci_id: null,
    },
    showModalPen: false,
    //Penyimpanan
    blok_id: [],
    palet_id: [],
    rak_id: [],
    laci_id: [],
    //List penyimpanan yang akan di simpan
    pilihbarang: null,
    addedItems: [],
    fieldsAddedItems: [
      { key: "no", label: "No" },
      { key: "barang", label: "Barang" },
      { key: "lokasi", label: "Lokasi" },
      { key: "action", label: "#" },
    ],
    required,
    password,
    email,
    confirmed,
    params: {
      from_gudang_id: null,
    },
    loading: false,
    loadingtable: false,
    id: null,
    form: {},
    dataGudang: [],
    otherGudangs: [],
    perPage: 10,
    pageOptions: [5, 10, 50, 100],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    penyimpananBarangs: [],
    directive: {
      "b-tooltip": VBTooltip,
    },
    ket_selesai: [
      {
        0: "Belum ditempatkan",
        1: "Sudah",
      },
      {
        0: "light-danger",
        1: "light-success",
      },
    ],
    fieldsBarangs: [
      { key: "penyimpanan", label: "Pilih" },
      { key: "no", label: "No", sortable: true },
      { key: "nama", label: "Nama Barang", sortable: true },
      { key: "kode", label: "Kode" },
      { key: "varian", label: "Varian" },
      { key: "satuan", label: "Satuan" },
      // { key: "action", label: "#" },
    ],
    fieldsGudang: [
      { key: "checkbox", label: "#" },
      {
        key: "no",
        label: "No",
      },
      { key: "fotopro", label: "Foto" },
      {
        key: "kode",
        label: "Barcode",
        // sortable: true,
      },
      {
        key: "nama",
        label: "Nama Barang",
        // sortable: true
      },
      // { key: "varian", label: "Varian", sortable: true },
      // {
      //   key: "jumlah",
      //   label: "Qty WMS",
      //   sortable: true,
      // },
      {
        key: "stok_ginee",
        label: "Qty Ginee",
        // sortable: true,
      },
      {
        key: "stocks",
        label: "STOCK",
        // sortable: true,
      },
      // { key: "actionsCabang", label: "Aksi" },
      // {
      //   key: "satuan",
      //   label: "Total Kuantitas",
      //   sortable: true,
      // },
      // { key: "blok", label: "Blok", sortable: true },
      // { key: "palet", label: "Palet", sortable: true },
      // { key: "rak", label: "Rak", sortable: true },
      // { key: "laci", label: "Laci", sortable: true },
      // {
      //   key: "lokasi",
      //   label: "Lokasi Penyimpanan",
      //   sortable: true,
      // },
      // { key: "tanggal_invoice", label: "Tanggal Invoice", sortable: true },
      // { key: "from.nama_gudang", label: "Gudang Asal", sortable: true },
      // { key: "publish", label: "Publish?" },
    ],
    fieldsCabang: [
      {
        key: "no",
        label: "No",
      },
      {
        key: "kode",
        label: "Kode",
        sortable: true,
      },
      { key: "nama", label: "Nama Barang", sortable: true },
      { key: "varian", label: "Varian", sortable: true },
      {
        key: "jumlah",
        label: "Kuantitas",
        sortable: true,
      },
      {
        key: "satuan",
        label: "Total Kuantitas",
        sortable: true,
      },
      { key: "blok", label: "Blok", sortable: true },
      { key: "palet", label: "Palet", sortable: true },
      { key: "rak", label: "Rak", sortable: true },
      { key: "laci", label: "Laci", sortable: true },
      // {
      //   key: "lokasi",
      //   label: "Lokasi Penyimpanan",
      //   sortable: true,
      // },
      // { key: "tanggal_invoice", label: "Tanggal Invoice", sortable: true },
      // { key: "from.nama_gudang", label: "Gudang Asal", sortable: true },
      { key: "publish", label: "Publish?" },
      { key: "actionsCabang", label: "Aksi" },
    ],
    showModal: false,
    fieldsasli: null,
    fields: [
      {
        key: "no",
        label: "No",
      },
      {
        key: "gudang.nama_gudang",
        label: "Gudang",
      },
      { key: "no_invoice", label: "No. Invoice", sortable: true },
      {
        key: "tanggal",
        label: "Tanggal pengiriman",
        sortable: true,
      },
      // { key: "tanggal_invoice", label: "Tanggal Invoice", sortable: true },
      { key: "selesai", label: "Status" },
      { key: "actions", label: "Aksi" },
    ],
    published: false,
    currentImage: null,
    currentBarcode: null,
    selectedginee: [],
    allChecked: false,
    //FILTER 2
    loadingkategori: false,
    itemsKategori: [],
    kategori_id: null,
    searchOptions: [],
    customefilter: null,
    kelas_id: null,
    itemsKelas: [],
    loadingkelas: false,
    sortBarang: null,
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    "gudangasal.jumlah"(val) {
      if (val == 0){
        this.displayWarning({
          message: 'Stok produk 0, gudang asal tidak boleh stoknya 0'
        })
        this.gudangasal = null
      }
    },
    "form.jenis"(j) {
      if (j == 1) {
        this.form.no_invoice = this.getNewNoInvoice();
      } else {
        this.form.no_invoice = null;
      }
    },
    "form.blok_id"(blok_id) {
      this.getpalet(blok_id);
    },
    "form.palet_id"(palet_id) {
      this.getRak(palet_id);
    },
    "form.rak_id"(rak_id) {
      this.getLaci(rak_id);
    },
    perPage(value) {
      if (value) {
        this.getpenyimpananBarangs();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getpenyimpananBarangs();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getpenyimpananBarangs();
        // this.items
      }
    },
    perPageBarangs(value) {
      if (value) {
        this.getDataBarangs();
      }
    },
    // filterBarangs(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getDataBarangs();
    //   }
    // },
    currentPageBarangs(val) {
      if (val) {
        this.getDataBarangs();
        // this.items
      }
    },
    allChecked(val) {
      if (val) {
        this.selectedginee = this.penyimpananBarangs;
      } else {
        this.selectedginee = [];
      }
    },
  },
  methods: {
    totalJumlah(item) {
  return item.reduce((total, data) => total + data.minimum_stok, 0);
},
    // sortedStocks(item) {
    //   return item.stocks.sort((a, b) => {
    //     // Sorting based on blok.blok in ascending order
    //     if (a.blok.blok < b.blok.blok) return -1;
    //     if (a.blok.blok > b.blok.blok) return 1;
    //     return 0;
    //   });
    // },
    getKategori() {
      this.loadingkategori = true;
      this.$store
        .dispatch("kategori/getData")
        .then(() => {
          let items = JSON.parse(
            JSON.stringify(this.$store.state.kategori.datas)
          );
          // let items_total = this.$store.state.kategori.totals;
          this.itemsKategori = items;
          this.itemsKategori.map((item) => {
            item.text = item.kategori;
            item.value = item.id;
          });
          // console.log('KATT', this.itemsKategori)
          this.loadingkategori = false;
          // this.items = this.$store.state.kategori.datas
          // this.totalRows = this.items.length;
        })

        .catch((e) => {
          this.loadingkategori = false;
          this.displayError(e);
          return false;
        });
    },
    getKelas() {
      this.loadingkelas = true;
      this.$store
        .dispatch("kelas/getData")
        .then(() => {
          let items = JSON.parse(JSON.stringify(this.$store.state.kelas.datas));
          // let items_total = this.$store.state.kelas.totals;
          this.itemsKelas = items;
          this.itemsKelas.map((item) => {
            item.text = item.kelas;
            item.value = item.id;
          });
          // console.log('KATT', this.itemsKelas)
          this.loadingkelas = false;
          // this.items = this.$store.state.kelas.datas
          // this.totalRows = this.items.length;
        })

        .catch((e) => {
          this.loadingkategori = false;
          this.displayError(e);
          return false;
        });
    },
    cekstokginee() {
      if (this.selectedginee.length <= 0) {
        this.displayWarning({
          message: "Harap pilih penyimpanan barang terlebih dahulu",
        });
        return;
      }
      this.loading = true;
      const ids = this.selectedginee.map((item) =>
        item.id ? item.id : item.no_batch
      );
      // console.log('aji', ids)
      const payload = {
        barang_id: ids,
      };
      this.$store
        .dispatch("ginee/getDatastokginee", payload)
        .then((response) => {
          // this.items = null
          // console.log('HEMMM', response)
          this.getpenyimpananBarangs();
          this.loading = false;
          this.selectedginee = null
          this.selectedginee = []
          this.allChecked = false
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
          this.displayError(e);

          return false;
        });
    },
    updatestokginee() {
      if (this.selectedginee.length <= 0) {
        this.displayWarning({
          message: "Harap pilih penyimpanan barang terlebih dahulu",
        });
        return;
      }
      this.loading = true;
      const ids = this.selectedginee.map((item) =>
        item.id ? item.id : item.no_batch
      );
      // console.log('aji', ids)
      const result = this.selectedginee.map((item) => ({
        barang_id: item.id,
        new_available_stok: item.jumlah,
      }));
      // const payload = {
      //   barang_id: ids
      // };

      // Cetak hasil
      console.log(result);

      this.$store
        .dispatch("ginee/updatestokginee", result)
        .then((response) => {
          this.displayError({
            message: "Stok ginee berhasil di update",
          });
          this.getpenyimpananBarangs();
          this.loading = false;

          this.selectedginee = null
          this.selectedginee = []
          this.allChecked = false
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
          this.displayError(e);

          return false;
        });
    },

    openModalmin() {
      // Set gambar saat ini dan buka modal

      // console.log("AJI", this.selectedginee.map(item => item.stocks).flat())
      this.$refs.minmodal.show();
    },

    ubahstocktf() {
      if (this.gudangasal == null) {
        this.displayWarning({
          message: "Harap pilih gudang asal terlebih dahulu",
        });
        return;
      }
      if (this.gudangtujuan == null) {
        this.displayWarning({
          message: "Harap pilih gudang tujuan terlebih dahulu",
        });
        return;
      }
      if (this.gudangasal.jumlah < this.stocktf) {
        this.displayWarning({
          message: "Harap jumlah yang akan ditransfer stoknya harus kurang atau sama dengan dari gudang asal",
        });
        return;
      }
      this.loading = true;
      let payload = [];
      payload.push({
          tujuan_stok_id: this.gudangtujuan.id,
          barang_id: this.datatf.id,
          jumlah: this.stocktf,
          asal_stok_id: this.gudangasal.id,
          keterangan: '-',
        });
        this.$store
        .dispatch("mutasi/saveToko", payload)
        .then(() => {
          this.displaySuccess({
            message: "Transfer Stock berhasil disimpan!",
          });
          // setTimeout(() => {
          //   this.$router.push("/mutasi");
          // }, 1000);
          this.loading = false

      this.$bvModal.hide("transfer-modal");
      this.datatf = null
      this.datatfgudang = []
      this.gudangtujuan = null
      this.stocktf = 0
      this.gudangasal = null
      this.getpenyimpananBarangs()
        })
        .catch((e) => {
          this.displayError(e);
          return false;
          this.loading = false
        });
    },
    modaltransfer(item) {
      // Set gambar saat ini dan buka modal
      // this.$refs.transfermodal.show();
      this.datatf = item
      this.datatfgudang = item.stocks
      this.datatfgudang.forEach((hem) => {
    hem.text = hem.blok.blok; // Menambahkan properti text
    hem.value = hem.id; // Menambahkan properti value
});

      this.$bvModal.show("transfer-modal");
    },
    ubahminstok() {
      if (this.selectedginee.length <= 0) {
        this.displayWarning({
          message: "Harap pilih penyimpanan barang terlebih dahulu",
        });
        return;
      }
      this.loading = true;
      // const ids = this.selectedginee.map(item => item.barang.id ? item.barang.id : item.barang.no_batch);
      const hasil = this.selectedginee.map(item => item.stocks).flat()
      const hasilminimum = hasil.map(item => {
    return {
        id: item.id,
        gudang_id: item.gudang_id, // Ubah sesuai kebutuhan
        blok_id: item.blok_id, // Ubah sesuai kebutuhan
        barang_id: item.barang_id, // Ubah sesuai kebutuhan
        is_published: item.is_published, // 1 atau 0 sesuai logika
        minimum_stok: this.minimum_stok, // Ubah sesuai kebutuhan
    };
});
      // const result = this.selectedginee.stocks.map((item) => {
      //   return {
      //     id: item.id,
      //     gudang_id: item.gudang_id, // Ubah sesuai kebutuhan
      //     blok_id: item.blok_id, // Ubah sesuai kebutuhan
      //     barang_id: item.barang_id, // Ubah sesuai kebutuhan
      //     is_published: item.is_published, // 1 atau 0 sesuai logika
      //     minimum_stok: this.minimum_stok, // Ubah sesuai kebutuhan
      //   };
      // });
      this.$store
        .dispatch("tokopenyimpanan/save2", hasilminimum)
        .then((response) => {
          // this.items = null
          // console.log('HEMMM', response)
          this.displaySuccess({
            message: "Berhasil menyimpan minimum stok",
          });
          this.getpenyimpananBarangs();
          // this.$refs.minmodal.close()
          this.$refs.minmodal.hide();
          this.loading = false;

          this.selectedginee = null
          this.selectedginee = []
          this.allChecked = false
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
          this.displayError(e);

          return false;
        });
    },
    openModal(src, src2) {
      // Set gambar saat ini dan buka modal
      this.currentImage = src;
      this.currentBarcode = src2;
      if (this.$refs.imageModal) {
        this.$refs.imageModal.show();
      } else {
        console.error("Ref imageModal tidak ditemukan.");
      }
      // this.$refs.imageModal.show();
    },
    onFilteredBarangs(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsBarangs = filteredItems.length;
      this.currentPageBarangs = 1;
    },
    submitbrg() {
      this.ubah.blok_id = this.ubah.blok.id;
      this.ubah.barang_id = this.pilihbarang2.id;
      let payload = {
        id: this.ubah.id,
        blok_id: this.ubah.blok.id,
        barang_id: this.pilihbarang2.id,
        gudang_id: this.ubah.gudang_id,
        // palet_id : ,
        // rak_id : ,
        // laci_id : ,
      };

      this.$store
        .dispatch("tokopenyimpanan/save2", [payload])
        .then(() => {
          this.loading = false;
          this.displaySuccess({
            message: "Penyimpanan Barang berhasil diubah!",
          });
          this.getpenyimpananBarangs();
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    submitpen() {
      let payload = {
        id: this.ubahpen.id,
        blok_id: this.ubahpen.blok_id,
        barang_id: this.ubahpen.barang_id,
        gudang_id: this.ubahpen.gudang_id,
      };
      if (this.ubahpen.palet_id) {
        payload.palet_id = this.ubahpen.palet_id;
      }
      if (this.ubahpen.rak_id) {
        payload.rak_id = this.ubahpen.rak_id;
      }
      if (this.ubahpen.palet_id) {
        payload.laci_id = this.ubahpen.laci_id;
      }
      this.$store
        .dispatch("tokopenyimpanan/save2", [payload])
        .then(() => {
          this.loading = false;
          this.displaySuccess({
            message: "Penyimpanan Barang berhasil diubah!",
          });
          this.getpenyimpananBarangs();
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    ubahbrg(item) {
      this.ubah = item;
      this.showModalBrg = true;
    },
    ubahpeny(item) {
      this.ubahpen = item;
      this.ubahpen.blok_id = item.blok.id;
      this.getpalet(item.blok.id);
      this.ubahpen.palet_id = item.palet ? item.palet.id : null;
      this.ubahpen.rak_id = item.rak ? item.rak.id : null;
      this.ubahpen.laci_id = item.laci ? item.laci.id : null;
      this.showModalPen = true;
    },
    async togglePublish(item) {
      if (item.is_published) {
        try {
          await this.$store.dispatch("tokopenyimpanan/publish", {
            id: item.id,
            is_published: 1,
          });
          this.displaySuccess({
            text: "Data berhasil Dipublish",
          });
        } catch (e) {
          this.displayError(e);
        }
      } else {
        try {
          await this.$store.dispatch("tokopenyimpanan/publish", {
            id: item.id,
            is_published: 0,
          });
          this.displaySuccess({
            text: "Data berhasil Diunpublish",
          });
        } catch (e) {
          this.displayError(e);
        }
      }
    },
    add() {
      // this.resetForm();
      this.showModal = true;
    },
    coba() {
      if (
        this.isAdminGudang ||
        this.isStaffGudang ||
        this.isSpvcrm ||
        this.isSPV
      ) {
        return (this.fieldsasli = this.fieldsGudang);
      } else if (this.isCabang) {
        return (this.fieldsasli = this.fieldsCabang);
      } else {
        this.fieldsasli = this.fields;
      }
    },
    batal(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Pengiriman Barang ke Cabang ini akan dibatalkan`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        // const allowed = result.value;
        if (result.value) {
          // this.loading = true;
          this.$store
            .dispatch("pengiriman/batal", {
              pengiriman_id: item.id,
              metode: "transaksi",
            })
            .then(() => {
              // this.loading = false;
              this.displaySuccess({
                text: "Data berhasil dibatalkan",
              });
              this.getpenyimpananBarangs();
            })
            .catch((e) => {
              // this.loading = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    async exportpengirimanBarang({ tgl_awal, tgl_akhir, type_export }) {
      if (!tgl_awal || !tgl_akhir) {
        this.displayWarning({
          text: "Harap isi tanggal awal dan akhir!",
        });
        return false;
      }

      const params = {
        tgl_awal,
        tgl_akhir,
        type_export,
      };

      try {
        this.loading = true;
        const response = await this.doExport(params);
        this.loading = false;
        await window.open(response);
        setTimeout(async () => {
          // delete selected file
          const arrFile = response.split("/");
          const filename = arrFile[arrFile.length - 1];

          await this.clearExportedFile(filename);
        }, 1000);
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    submit() {
      this.addedItems.map((item) => {
        delete item.barang;
        delete item.lokasi;
      });
      let payload = [];
      Object.assign(payload, this.addedItems);

      this.loading = true;
      this.$store
        .dispatch("tokopenyimpanan/save", payload)
        .then(() => {
          this.loading = false;
          this.displaySuccess({
            message: "Penyimpanan Barang berhasil ditambahkan!",
          });

          setTimeout(() => {
            this.$router.push("/cabang/penyimpanan");
          }, 1000);
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    getDataGudang() {
      let coba = [this.myGudang];
      coba.map((item) => {
        item.value = item.id;
        item.text = item.nama_gudang;
      });
      // this.dataGudang = gudangs
      this.dataGudang = coba;
    },
    async getNewNoInvoice() {
      const noInvoice = await this.$store.dispatch(
        "pengiriman/getNewNoInvoice"
      );
      this.form.no_invoice = noInvoice.message.format;
    },
    add() {
      // this.resetForm();
      this.showModal = true;
    },
    resetForm() {
      this.id = null;
      this.form.id = null;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Pengiriman Barang ke Cabang ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        // const allowed = result.value;
        if (result.value) {
          // this.loading = true;
          this.$store
            .dispatch("pengiriman/deletepengiriman", {
              pengiriman_id: item.id,
              metode: "main",
            })
            .then(() => {
              // this.loading = false;
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.getpenyimpananBarangs();
            })
            .catch((e) => {
              // this.loading = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getpenyimpananBarangs() {
      this.loadingtable = true;
      if ((this.loadingtable = true)) {
        this.$swal({
          title: "Sedang Memproses...",
          text: "Tunggu sebentar, proses sedang berjalan.",
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        const perPage = parseInt(this.perPage);
        const currentPage = (parseInt(this.currentPage) - 1) * perPage;
        const payload = {
          search: this.filter != null ? this.filter : null,
          order: "desc",
          start: currentPage,
          length: this.perPage,
          blok_id: this.cariblok ? this.cariblok : null,
          // palet_id: this.caripalet,
          // rak_id: this.carirak,
          // laci_id: this.carilaci,
          // barang_id: this.caribarang,
          // kode_barang: this.carikodebarang,
          gudang_id: this.myGudang
            ? this.myGudang.data
              ? this.myGudang.data.id
              : this.myGudang.id
            : null,
          kategori_id: this.kategori_id ? this.kategori_id : null,
          sort: this.sortBarang ? this.sortBarang : null,
          is_pre_order: this.is_pre_order,
          // filter_by: "nama_lengkap",
        };
        if (this.searchOptions == "kelas_id") {
          payload.kelas_id = this.kelas_id ? this.kelas_id : null;
        }
        if (this.searchOptions == "main_sku") {
          payload.main_sku = this.customefilter ? this.customefilter : null;
        }
        if (this.searchOptions == "kode") {
          payload.kode = this.customefilter ? this.customefilter : null;
          payload.no_batch = this.customefilter ? this.customefilter : null;
        }
        // let payload = this.isAdminGudang
        //   ? { from_gudang_id: this.myGudang.id, order: "desc" }
        //   : { order: "desc" };
        await this.$store
          .dispatch("barang/getDatawithstok", payload)
          .then((response) => {
            // this.penyimpananBarangs = penyimpananBarangs;
            // this.totalRows = this.penyimpananBarangs.length;
            let items = JSON.parse(
              JSON.stringify(this.$store.state.barang.datas)
            );
            let items_total = this.$store.state.barang.totals;
            this.penyimpananBarangs = items;
            this.totalRows = items_total;
            this.loadingtable = false;
            this.$swal.close();
          })
          .catch((e) => {
            this.$swal.close();
            this.loading = false;
            this.displayError(e);
            return false;
          });
      }
      // else {
      //   this.displayWarning({
      //     message: "Harap isi form tanggal yang disediakan",
      //   });
      // }
    },
    async getDataBarangs() {
      // if (this.items > 1)
      this.loading = true;
      this.loaded = false;
      const perPageBrg = parseInt(this.perPageBarangs);
      const currentPageBrg =
        (parseInt(this.currentPageBarangs) - 1) * perPageBrg;
      const payload = {
        search: this.caribarang != null ? this.caribarang : null,
        order: "desc",
        start: currentPageBrg,
        length: this.perPageBarangs,
        // filter_by: "nama_lengkap",
        // kode: this.caribarang ? this.caribarang : "",
      };
      this.$store
        .dispatch("barang/getDataV2", payload)
        .then((response) => {
          // this.items = null
          // console.log('HEMMM',this.items)

          let items = JSON.parse(
            JSON.stringify(this.$store.state.barang.datas)
          );
          let items_total = this.$store.state.barang.totals;
          this.dataBarangs = items;
          const barangsId = response.map((barang) => barang.id);
          // get stock
          // this.getStockBarang(barangsId)
          this.totalRowsBarangs = items_total;
          // this.$refs.table.refresh()
          // console.log('HEMMM',this.items)
          // this.$root.$emit("bv::refresh::table", "table");
          this.loaded = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loaded = true;
          this.loading = false;
          console.error(e);
          this.displayError(e);

          return false;
        });
    },
    getDatablok() {
      let params = {
        jenis: 1,
      };
      // if (this.isAdminGudang) {
      params.gudang_id = this.myGudang ? this.myGudang.id : null;
      // }
      this.$store.dispatch("tokoblok/getData", params).then(() => {
        let ter = JSON.parse(JSON.stringify(this.$store.state.tokoblok.datas));
        ter.map((item) => {
          item.value = item.id;
          item.text = item.blok;
        });
        this.blok_id = ter;
      });
    },
    addBarang() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          let data = {};
          Object.assign(data, this.form);
          data.barang = this.pilihbarang.nama;
          data.barang_id = this.pilihbarang.id;
          data.jumlah = 0;
          // get lokasi
          const blok = this.blok_id.find((blok) => blok.value == data.blok_id);
          const palet = data.palet_id
            ? this.palet_id.find((palet) => palet.value == data.palet_id)
            : null;
          const rak = data.rak_id
            ? this.rak_id.find((rak) => rak.value == data.rak_id)
            : null;
          const laci = data.laci_id
            ? this.laci_id.find((laci) => laci.value == data.laci_id)
            : null;

          let lokasi = blok ? `Blok: ${blok.text}` : "";
          if (palet) {
            lokasi += `, Palet: ${palet.text}`;
          }
          if (rak) {
            lokasi += `, Rak: ${rak.text}`;
          }

          if (laci) {
            lokasi += `, Laci: ${laci.text}`;
          }
          data.lokasi = lokasi;
          this.addedItems.push(data);
          // this.form.blok_id = null,
          //   this.form.palet_id = null,
          //   this.form.rak_id = null,
          //   this.form.laci_id = null
        }
      });
    },
    getpalet(blok_id) {
      this.palet_id = [];
      this.$store.dispatch("tokopalet/getData", { blok_id }).then((palets) => {
        palets.forEach((palet) => {
          this.palet_id.push({
            value: palet.id,
            text: palet.palet,
          });
        });
      });
    },
    getRak(palet_id) {
      this.rak_id = [];
      this.$store.dispatch("tokorak/getData", { palet_id }).then((raks) => {
        raks.forEach((rak) => {
          this.rak_id.push({
            value: rak.id,
            text: rak.rak,
          });
        });
      });
    },
    getLaci(rak_id) {
      this.laci_id = [];
      this.$store.dispatch("tokolaci/getData", { rak_id }).then((lacis) => {
        lacis.forEach((laci) => {
          this.laci_id.push({
            value: laci.id,
            text: laci.laci,
          });
        });
      });
    },
  },
  async created() {
    this.loading = true;
    await this.getDatablok();
    await this.getKategori();
    await this.getKelas();
    await this.getpenyimpananBarangs();
    // await this.getDataGudang();
    await this.coba();
    this.loading = false;
  },
};
</script>
<style>
.v-align-top {
  vertical-align: top !important;
}
</style>
