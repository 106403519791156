<template>
  <b-overlay :show="loading">
    <!-- <div class="running-text-container mb-1">
      <div class="running-text">
        Selamat datang di website kami! Nikmati pengalaman terbaik bersama kami!
        Mohon maaf sebelumnya, fitur ini belum berfungsi (masih layout) karena masih tahap analisis sama tim. Terima
        kasih
      </div>
    </div> -->
    <b-row>
      <b-col class="">
        <!-- <b-button-group>
          <b-button @click.prevent="adddisposal" variant="primary">
            {{ $t('Add') }}
          </b-button>
        </b-button-group> -->
        <!-- <xlsx-workbook>
          <xlsx-sheet :collection="items" sheet-name="Sheet1" />
          <xlsx-download filename="Export Data Disposal">
            <b-button v-if="allowCreate()" variant="success">
              {{ $t('Export') }}
            </b-button>
          </xlsx-download>
        </xlsx-workbook>
        <b-button v-if="allowCreate()" @click.prevent="addimport" variant="warning">
          {{ $t('Import') }}
        </b-button>
        <modal-import @getData="getData"></modal-import> -->
      </b-col>
    </b-row>
    <b-card>
      <!-- <modal-disposal :item="currentItem" @loading="toggleLoading" @submit="submit" @getData="getData"></modal-disposal> -->
      <b-row>
        <b-col>
          <h4>Per Attachment</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="6" class="my-1">
          <b-form-group
            class="mb-0"
            :label="'Per page'"
            label-size="sm"
            label-for="perPageSelect"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="6" class="my-1">
          <b-form-group
            :label="$t('Sort')"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <!-- <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" @change="getData()" class="w-75">
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
</b-form-select> -->
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                @change="getData()"
                class="w-100"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="8" sm="12" class="my-1">
          <b-form-group
            :label="$t('Filter')"
            label-for="filterInput"
            class="mb-0"
            size="sm"
          >
            <b-input-group>
              <b-form-input
                v-model="filter"
                placeholder="Tekan enter untuk cari"
                size="sm"
                @keydown.enter.prevent="getData()"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="getData()"
                  size="sm"
                >
                  {{ $t("Search") }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <template v-if="items.length > 0">
          <b-col cols="12">
            <b-table
              small
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
            >
              <template #cell(no)="row">
                <strong class="text-center">
                  {{ row.index + 1 }}
                </strong>
              </template>
              <template #cell(nama_pic)="{ item }">
                {{ truncateString(item.nama, 15) }}<br />
                <small>{{
                  truncateString(item.no_hp_pic, 15)
                    ? truncateString(item.no_hp_pic, 15)
                    : "-"
                }}</small>
                <!-- <span :title="item.nama">{{truncateString(item.email, 15)}}</span><br> -->
              </template>
              <template #cell(fotopro)="{ item }">
                <template v-if="item.attachment" class="image-gallery">
                  <!-- style="width: 50px" -->
                  <b-button
                  variant="warning"
                  size="sm"
                  class="mr-1"
                    @click="openModal(item.attachment)"
                    :src="'https://api-heykama.notive.my.id/' + item.attachment"
                    alt="Image description"
                  >
                  <feather-icon icon="FileIcon" />
                  {{$t('Attachment')}}
                </b-button>
                </template>
                <small v-else class="text-danger"
                  >Attachment item not yet created</small
                >
                
              </template>
              <template #cell(nama_instansi)="{ item }">
                {{ truncateString(item.instansi, 15) }}<br />
                <small>{{
                  truncateString(item.nohp, 15)
                    ? truncateString(item.nohp, 15)
                    : "-"
                }}</small>
              </template>
              <template #cell(top)="{ item }">
                <span :title="item.top">{{ truncateString(item.top, 30) }}</span
                ><br />
              </template>
              <template #cell(alamat)="{ item }">
                <span :title="item.alamat">{{
                  truncateString(item.alamat, 30)
                }}</span
                ><br />
              </template>
              <template #cell(memo)="{ item }">
                <span :title="item.memo">{{
                  truncateString(item.memo, 30)
                }}</span
                ><br />
              </template>
              <template #cell(kode)="{ item }">
                {{
                  item.penyimpanan
                    ? item.penyimpanan.barang_short
                      ? item.penyimpanan.barang_short.kode
                      : "-"
                    : "-"
                }}
              </template>
              <template #cell(kategori)="{ item }">
                {{ item.barang.kategori ? item.barang.kategori.kategori : "-" }}
              </template>
              <template #cell(penyimpanan)="{ item }">
                <span v-if="item.disposal_datas">
                  {{
                    item.disposal_datas
                      ? item.disposal_datas.barang_short
                        ? item.disposal_datas.barang_short.varian_item[0].varian
                        : "-"
                      : "-"
                  }}<br />
                  <small
                    >{{
                      item.disposal_datas
                        ? item.disposal_datas.barang_short
                          ? item.disposal_datas.barang_short.kategori.kategori
                          : "-"
                        : "-"
                    }}
                    /
                    {{
                      item.disposal_datas
                        ? item.disposal_datas.barang_short
                          ? item.disposal_datas.barang_short.nama
                          : "-"
                        : "-"
                    }}</small
                  >
                </span>
                <i v-else class="text-danger">Data not found.</i>
              </template>
              <template #cell(jumlah)="{ item }">
                <span v-if="item.penyimpanan">
                  {{ item.jumlah }} /
                  {{
                    item.penyimpanan
                      ? item.penyimpanan.barang_short
                        ? formatRupiah(
                            item.penyimpanan.barang_short.harga_dasar *
                              item.jumlah
                          )
                        : "-"
                      : "-"
                  }}<br />
                </span>
                <i v-else class="text-danger">Data not found.</i>
              </template>

              <template #cell(actions)="{item}">
                <!-- <b-button :title="$t('Edit')" size="sm" @click="edit(row.item)" class="mr-1" variant="info">
                  <feather-icon icon="EditIcon" />
                </b-button> -->
                <b-button
                  :title="$t('Delete')"
                  size="sm"
                  @click="remove(item)"
                  class="mr-1"
                  variant="danger"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>

              <template #cell(attachment)="{item}">
            <div>
              <template v-if="isImage(item.attachment)">
                <img
      :title="$t('Click Me')"
                  :src="'https://api-heykama.notive.my.id/' + item.attachment"
                  alt="Lampiran"
                  class="img-thumbnail"
                  style="max-width: 100px;"
                  @click="openModal(item.attachment)"
                />
                <b-modal
                  id="image-modal"
                  title="Preview Attachment Item"
                  ref="imageModal"
                  :hide-footer="true"
                  :size="isImages ? 'lg' : 'xl'"
                  centered
                >
<template v-if="currentFile">
  <div ref="fileViewer">
    <template v-if="isImages">
      <img
        :src="'https://api-heykama.notive.my.id/' + currentFile"
        class="img-fluid"
        alt="Full-size"
      />
    </template>
    <template v-else-if="isPDFs">
      <iframe
        :src="'https://api-heykama.notive.my.id/' + currentFile"
        class="w-100"
        style="height: 500px; border: none;"
      ></iframe>
    </template>
    <template v-else>
      <a
        :href="'https://api-heykama.notive.my.id/' + currentFile"
        target="_blank"
        class="btn btn-primary"
      >
        Download File
      </a>
    </template>
    <small v-if="!currentFile" class="text-danger">File not found</small>
  </div>
</template>
                </b-modal>
              </template>
              <template v-else-if="isPDF(item.attachment)">
                <!-- :href="'https://api-heykama.notive.my.id/' + item.attachment"
                target="_blank" -->
                <a
                  @click="openModal(item.attachment)"
                  class="btn btn-primary btn-sm"
                >
                  Lihat PDF
                </a>
              </template>
              <template v-else>
                <a
                  :href="'https://api-heykama.notive.my.id/' + item.attachment"
                  target="_blank"
                  class="btn btn-secondary btn-sm"
                >
                  Download File
                </a>
              </template>
            </div>
          </template>

          <!-- Custom Cell for Items -->
          <template #cell(produk)="{item}">
            <ul class="list-unstyled mb-0">
              <li v-for="(item, index) in item.disposal_datas" :key="index">
                <b-row>
                  <b-col>
                <strong>{{ item.penyimpanan.barang_short.nama }}</strong> - {{ item.penyimpanan.barang_short.varian }} - {{
                      item.penyimpanan
                        ? item.penyimpanan.barang_short
                          ? item.penyimpanan.barang_short.satuan.satuan
                          : "-"
                        : "-"
                    }}<br />
                  <small
                    >{{
                      item.penyimpanan
                        ? item.penyimpanan.barang_short
                          ? item.penyimpanan.barang_short.kategori.kategori
                          : "-"
                        : "-"
                    }}</small
                  >
                </b-col>
                <b-col>
                  {{ item.jumlah }} - {{
                      item.penyimpanan
                        ? item.penyimpanan.barang_short
                          ? item.penyimpanan.barang_short.satuan.satuan
                          : "-"
                        : "-"
                    }}<br />
                </b-col>
                <b-col>
                  <b-button
                  :title="$t('Delete')"
                  size="sm"
                  @click="remove(item)"
                  class="mr-1"
                  variant="danger"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
                <br />
                </b-col>
              </b-row>
                <!-- <span>{{ formatCurrency(item.price) }}</span> -->
              </li>
            </ul>
          </template>
            </b-table>
          </b-col>

          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </template>
        <template v-else>
          <b-col cols="12">
            <div class="alert alert-danger p-2">
              <strong
                >{{ $t("Data not found") }}
                <b-button @click="getData()" variant="primary"
                  >Refresh</b-button
                ></strong
              >
            </div>
          </b-col>
        </template>
      </b-row>
    </b-card>
    <!-- <section>
      <h3>Create XLSX</h3>
      <div>
        <input v-model="sheetName" placeholder="type a new sheet name" />
        <button v-if="sheetName" @click="addSheet">Add Sheet</button>
      </div>

      <div>Sheets: {{ sheets }}</div>

      <xlsx-workbook>
        <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" />
        <xlsx-download>
          <button>Download</button>
        </xlsx-download>
      </xlsx-workbook>
    </section> -->
  </b-overlay>
</template>

<script>
import {
  XlsxRead,
  XlsxTable,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "../../../docs/dist/vue-xlsx.es.js";
// import ModalImport from './Import.vue'
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BTable,
  VBTooltip,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCard,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BButtonGroup,
  BOverlay,
} from "bootstrap-vue";
// import ModalDisposal from './components/Modal.vue'

import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";

export default {
  components: {
    Viewer,
    // ModalDisposal,
    // ModalImport,
    BCardActions,
    VBTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BOverlay,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BButtonGroup,
    BCard,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      // modalimport: false,
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [
        {
          name: "Sheet",
          data: [
            {
              nama: "Contoh: disposal nama",
              no_hp_pic: "841982419",
              instansi: "PT. heykama",
              nohp: "08124812894",
              email: "owner@heykama.com",
              top: "Cash",
              alamat: "Bandung, buahbatu",
              memo: "Note",
            },
          ],
        },
      ],
      collection: [{ a: 1, b: 2 }],
      //
      loading: false,
      showModalAdd: false,
      id: null,
      nama_jabatan: null,
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "desc",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      currentItem: {},
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "attachment",
          label: "Attachment",
        },
        {
          key: "produk",
          label: "Product",
        },
        // {
        //   key: "fotopro",
        //   label: "Attachment",
        // },
        // {
        //   key: "no",
        //   label: "No",
        // },
        // { key: "tanggal", label: "Tanggal", sortable: true },
        // { key: "kode", label: "Barcode", sortable: true },
        // { key: "penyimpanan", label: "Barang", sortable: true },
        // { key: "type", label: "Tipe", sortable: true },
        // { key: "jumlah", label: "Qty / Nominal", sortable: true },
        // { key: "keterangan", label: "Keterangan", sortable: true },
        // { key: "actions", label: "Aksi" },
      ],
      items: [],
      gudangOptions: [],
      currentImage: null,
      currentFile:null,
isImages:null,
isPDFs:null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getData();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getData();
      }
    },
    truncateString(str, maxLength) {
      if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + "...";
      } else {
        return str;
      }
    },
  },
  created() {
    // Set the initial number of items

    this.getGudang();
  },
  methods: {
    isImage(fileUrl) {
      const ext = fileUrl.split(".").pop().toLowerCase();
      return ["png", "jpg", "jpeg"].includes(ext);
    },
    isPDF(fileUrl) {
      return fileUrl.split(".").pop().toLowerCase() === "pdf";
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(value);
    },
    // openModal(src) {
    //   // Set gambar saat ini dan buka modal
    //   this.currentImage = src;
    //   // this.currentBarcode = src2;
    //   this.$refs.imageModal.show();

    //   // Inisialisasi Viewer.js
    //   this.$nextTick(() => {
    //     if (this.viewer) {
    //       this.viewer.destroy();
    //     }
    //     this.viewer = new Viewer(this.$refs.imageViewer, {
    //       inline: false,
    //       zoomable: true,
    //       scalable: true,
    //     });
    //   });
    // },
    openModal(fileUrl) {
    // Set file saat ini
    this.currentFile = fileUrl;

    // Cek ekstensi file
    const fileExtension = this.getFileExtension(fileUrl);

    // Tentukan jenis file
    this.isImages = ["png", "jpg", "jpeg"].includes(fileExtension);
    this.isPDFs = fileExtension === "pdf";

    // Jika file adalah gambar, inisialisasi Viewer.js
    if (this.isImages) {
      this.$nextTick(() => {
        if (this.viewer) {
          this.viewer.destroy();
        }
        this.viewer = new Viewer(this.$refs.fileViewer, {
          inline: false,
          zoomable: true,
          scalable: true,
        });
      });
    }

    // Tampilkan modal
    this.$refs.imageModal.show();
  },
  getFileExtension(fileUrl) {
    // Ambil ekstensi file dari URL
    return fileUrl.split(".").pop().toLowerCase();
  },
    adddisposal() {
      this.$router.push(`/outbound/ajuanmarketing/add`);
    },
    addimport() {
      // this.resetForm()
      this.$bvModal.show("modal-import");
    },
    async submitimport(cek) {
      cek.map((hem) => {
        hem.jenis = 2;
      });
      let payload = cek;
      // console.log('cek', cek)
      // if (this.id) {
      //   payload.id = this.id
      // }
      if (this.isOwner || this.isCEO) {
        payload.jenis = 2;
      } else {
        payload.jenis = 2;
      }
      try {
        this.loading = true;
        await this.$store.dispatch("disposal/save", payload);
        this.displaySuccess({
          message: "Berhasil",
        });
        this.getData();
        this.$bvModal.hide("modal-disposal");

        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    addSheet() {
      this.sheets.push({ name: this.sheetName, data: [...this.collection] });
      this.sheetName = null;
    },
    toggleLoading(val) {
      this.loading = val;
    },
    add() {
      this.resetForm();
      // this.$bvModal.show('modal-disposal')
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Disposal ${item.tanggal} ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          const payload = {
            // fungsi: 1,
            id: item.id,
          };
          this.$store
            .dispatch("disposal/delete", [payload])
            .then(() => {
              this.displaySuccess({
                message: "Berhasil",
              });
              this.resetForm();
              this.getData();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
    },
    async submit(payload) {
      if (this.id) {
        payload.id = this.id;
      }
      if (this.isOwner || this.isCEO) {
        payload.jenis = 1;
      } else {
        payload.jenis = 2;
      }
      try {
        this.loading = true;
        await this.$store.dispatch("disposal/save", [payload]);
        this.displaySuccess({
          message: "Berhasil",
        });
        this.getData();
        // this.$bvModal.hide('modal-disposal')

        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    edit(item) {
      // this.getDataId(item)
      // this.id = item.id
      this.currentItem = item;
      if (this.currentItem) {
        this.$router.push(`/outbound/ajuanmarketing/add/${item.id}`);
        // this.$bvModal.show('modal-disposal')
      }
      // console.log('CEK', this.currentItem)
    },
    async getDataId(item) {
      this.loading = true;
      this.$store
        .dispatch("disposal/getDataById", item.id)
        .then((response) => {
          let items = JSON.parse(
            JSON.stringify(this.$store.state.disposal.datas)
          );
          let items_total = this.$store.state.disposal.totals;
          this.items = items;
          this.totalRows = items_total;
          this.loading = false;
        })
        .catch((e) => {
          this.displayError(e);
          this.loading = false;
          return false;
        });
    },
    async getData() {
      this.loading = true;
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const params = {
        // search: this.filter ? this.filter : null,
        has_attachment: 1,
        order: this.sortDesc ? this.sortDesc : "desc",
        start: currentPage,
        length: this.perPage,
        id_gudang: this.myGudang
          ? this.myGudang.id
            ? this.myGudang.id
            : this.myGudang.data.id
          : this.gudangOptions[0].id,
        // filter_by: "nama_lengkap",
        // disposal_id: this.disposal,
        // id_disposal: this.disposal,
      };
      if (this.filter) {
        params.search = this.filter;
      }
      this.$store
        .dispatch("disposal/getDatapersurat", params)
        .then((response) => {
          let items = JSON.parse(
            JSON.stringify(this.$store.state.disposal.datas)
          );
          let items_total = this.$store.state.disposal.totals;
          this.items = items;
          this.totalRows = items_total;
          this.loading = false;
        })
        .catch((e) => {
          this.displayError(e);
          this.loading = false;
          return false;
        });
    },

    getGudang() {
      this.$store
        .dispatch("gudang/getData", { jenis: 1 })
        .then(() => {
          this.gudangOptions = this.$store.state.gudang.datas;
          this.gudangOptions.map((hem) => {
            hem.value = hem.id;
            hem.text = hem.nama_gudang;
          });
          this.getData();
          // this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.running-text-container {
  overflow: hidden;
  /* Hides the text that goes beyond the container */
  white-space: nowrap;
  /* Prevents text from wrapping */
  width: 100%;
  /* Full width */
  background-color: #f8f9fa;
  /* Optional: background color */
  padding: 10px 0;
  /* Optional: padding for visual enhancement */
  border: 1px solid #ddd;
  /* Optional: border */
}

.running-text {
  display: inline-block;
  /* Text behaves like an inline element */
  animation: marquee 10s linear infinite;
  /* Runs the marquee animation */
  font-size: 1.2rem;
  /* Optional: font size */
  color: #333;
  /* Optional: text color */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>
