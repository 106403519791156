var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon",
            "size": "24"
          }
        }), _c('p', {
          attrs: {
            "id": "cancel-label"
          }
        }, [_vm._v("Please wait...")])], 1)];
      },
      proxy: true
    }])
  }, [_vm.myGudang ? _c('header', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('h5', [_c('strong', [_vm._v("Lokasi Gudang: " + _vm._s(this.myGudang.nama_gudang ? this.myGudang.nama_gudang : this.myGudang.data.nama_gudang) + " ")])])])], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-form-group', [_c('label', [_c('strong', [_vm._v("Pilih lokasi 2 "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("(*)")])])]), _c('v-select', {
    attrs: {
      "options": _vm.optbloks,
      "label": "text"
    },
    on: {
      "input": function ($event) {
        return _vm.cekData();
      }
    },
    model: {
      value: _vm.blokasal,
      callback: function ($$v) {
        _vm.blokasal = $$v;
      },
      expression: "blokasal"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tanggal")])]), _c('p', [_vm._v(_vm._s(_vm.so.tanggal))])]), _c('b-col', {
    attrs: {
      "md": "5",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Keterangan")])]), _c('p', [_vm._v(_vm._s(_vm.so.keterangan ? _vm.so.keterangan : "-"))])]), _vm.so.status == 0 ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-badge', {
    attrs: {
      "variant": "light-danger",
      "size": "sm"
    }
  }, [_vm._v("Belum Selesai")])], 1) : _vm._e(), _vm.so.status == 1 ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-badge', {
    attrs: {
      "variant": "light-success",
      "size": "sm"
    }
  }, [_vm._v("Selesai")])], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingpenyimpanan,
      "rounded": "sm"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon",
            "size": "24"
          }
        }), _c('p', {
          attrs: {
            "id": "cancel-label"
          }
        }, [_vm._v("Please wait...")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-card', {
    staticStyle: {
      "overflow-y": "auto",
      "max-height": "500px"
    }
  }, [_c('b-table', {
    attrs: {
      "sticky-header": "400px",
      "small": "",
      "bordered": "",
      "striped": "",
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.penyimpanan,
      "per-page": _vm.perPage
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(stok)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.stok) + " ")];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.no_batch : "-"))])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.kategori ? item.barang.kategori.kategori : "-"))]), _vm._v(" / "), _c('small', [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : "-") + " ")]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.$t("Room")) + " : ")]), _c('small', [_vm._v(_vm._s(item.blok ? item.blok.blok : "-"))]), _c('small', [_vm._v(_vm._s(item.palet ? item.palet.palet : "-"))]), _c('small', [_vm._v(_vm._s(item.rak ? item.rak.rak : "-"))]), _c('small', [_vm._v(_vm._s(item.laci ? item.laci.laci : "-"))])];
      }
    }, {
      key: "cell(hasil_stok_opname)",
      fn: function (_ref4) {
        var item = _ref4.item,
          index = _ref4.index;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.hasil_stok_opname,
            callback: function ($$v) {
              _vm.$set(item, "hasil_stok_opname", $$v);
            },
            expression: "item.hasil_stok_opname"
          }
        })];
      }
    }, {
      key: "cell(so)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.id_stok_opname) + " ")];
      }
    }, {
      key: "cell(selisih)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.hasil_stok_opname - item.stok) + " "), _c('p', [item.stok < item.hasil_stok_opname ? _c('small', {
          staticClass: "text-info"
        }, [_c('i', [_vm._v("(Stok Hasil SO lebih)")])]) : _vm._e(), item.stok == item.hasil_stok_opname ? _c('small', {
          staticClass: "text-success"
        }, [_c('i', [_vm._v("(Stok sesuai)")])]) : _vm._e(), item.stok > item.hasil_stok_opname ? _c('small', {
          staticClass: "text-danger"
        }, [_c('i', [_vm._v("(Stok Hasil SO kurang)")])]) : _vm._e()])];
      }
    }, {
      key: "cell(kode2)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.penyimpanan.barang ? item.penyimpanan.barang.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.penyimpanan.barang ? item.penyimpanan.barang.no_batch : "-"))])];
      }
    }, {
      key: "cell(nama2)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.penyimpanan.barang ? item.penyimpanan.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.penyimpanan.barang.kategori.kategori))]), _vm._v(" / "), _c('small', [_vm._v(" " + _vm._s(item.penyimpanan.barang ? item.penyimpanan.barang.nama : "-") + " ")]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.$t("Room")) + " : ")]), _c('small', [_vm._v(_vm._s(item.penyimpanan.blok ? item.penyimpanan.blok : "-"))]), _c('small', [_vm._v(_vm._s(item.penyimpanan.palet ? item.penyimpanan.palet.palet : "-"))]), _c('small', [_vm._v(_vm._s(item.penyimpanan.rak ? item.penyimpanan.rak.rak : "-"))]), _c('small', [_vm._v(_vm._s(item.penyimpanan.laci ? item.penyimpanan.laci.laci : "-"))])];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1), _c('footer', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.so.status == 0 ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.simpanSelesai($event);
      }
    }
  }, [_vm._v("Selesaikan")]) : _vm._e()], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-simpan",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "no-close-on-backdrop": "",
      "ok-only": "",
      "centered": "",
      "size": "xl",
      "title": "Determine Adjustments"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex w-100 justify-content-end"
        }, [_c('b-button', {
          attrs: {
            "variant": "info",
            "disabled": _vm.buttonSelesai
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.selesai($event);
            }
          }
        }, [_vm._v(" Simpan ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_vm.penyimpananKurang.length > 0 ? _c('div', [_c('h2', [_vm._v("Less Adjustment")]), _c('b-table', {
    attrs: {
      "responsive": "",
      "fields": _vm.fieldsKurang,
      "items": _vm.adjustKurang
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.no_batch : "-"))])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.kategori ? item.barang.kategori.kategori : ""))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.nama : "-"))])];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref11) {
        var item = _ref11.item,
          index = _ref11.index;
        return [_c('b-form-input', {
          on: {
            "input": function ($event) {
              return _vm.checkSelisih(item, index);
            }
          },
          model: {
            value: item.jumlah,
            callback: function ($$v) {
              _vm.$set(item, "jumlah", $$v);
            },
            expression: "item.jumlah"
          }
        }), _c('p', [item.jumlah == 0 ? _c('small', {
          staticClass: "text-info"
        }, [_c('i', [_vm._v("Jumlah Setidaknya harus 1")])]) : _vm._e(), item.jumlah > item.selisih ? _c('small', {
          staticClass: "text-danger"
        }, [_c('i', [_vm._v("Jumlah maksimal " + _vm._s(item.selisih))])]) : _vm._e()])];
      }
    }, {
      key: "cell(type)",
      fn: function (_ref12) {
        var item = _ref12.item,
          index = _ref12.index;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.opttype
          },
          model: {
            value: item.type,
            callback: function ($$v) {
              _vm.$set(item, "type", $$v);
            },
            expression: "item.type"
          }
        })];
      }
    }, {
      key: "cell(keterangan)",
      fn: function (_ref13) {
        var item = _ref13.item,
          index = _ref13.index;
        return [_c('b-form-textarea', {
          model: {
            value: item.keterangan,
            callback: function ($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        })];
      }
    }], null, false, 1727214125)
  })], 1) : _vm._e(), _vm.penyimpananLebih.length > 0 ? _c('div', [_c('h2', [_vm._v("More Adjustment")]), _c('b-table', {
    attrs: {
      "responsive": "",
      "fields": _vm.fieldsLebih,
      "items": _vm.adjustLebih
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref14) {
        var item = _ref14.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.no_batch : "-"))])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref15) {
        var item = _ref15.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.kategori ? item.barang.kategori.kategori : "-"))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.nama : "-"))])];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref16) {
        var item = _ref16.item,
          index = _ref16.index;
        return [_c('b-form-input', {
          attrs: {
            "readonly": ""
          },
          model: {
            value: item.jumlah,
            callback: function ($$v) {
              _vm.$set(item, "jumlah", $$v);
            },
            expression: "item.jumlah"
          }
        }), _c('p', [item.jumlah == 0 ? _c('small', {
          staticClass: "text-info"
        }, [_c('i', [_vm._v("Jumlah Setidaknya harus 1")])]) : _vm._e(), item.jumlah > item.selisih ? _c('small', {
          staticClass: "text-danger"
        }, [_c('i', [_vm._v("Jumlah maksimal " + _vm._s(item.selisih))])]) : _vm._e()])];
      }
    }, {
      key: "cell(type)",
      fn: function (_ref17) {
        var item = _ref17.item,
          index = _ref17.index;
        return [_c('b-form-input', {
          attrs: {
            "readonly": ""
          },
          model: {
            value: item.type,
            callback: function ($$v) {
              _vm.$set(item, "type", $$v);
            },
            expression: "item.type"
          }
        })];
      }
    }, {
      key: "cell(keterangan)",
      fn: function (_ref18) {
        var item = _ref18.item,
          index = _ref18.index;
        return [_c('b-form-textarea', {
          model: {
            value: item.keterangan,
            callback: function ($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        })];
      }
    }], null, false, 1125952428)
  })], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }